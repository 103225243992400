import {
  Box,
  Button,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import GetAppIcon from '@material-ui/icons/GetApp';
import EventIcon from '@material-ui/icons/Event';
import { ShowLoadingText } from '../../utils/helperComponents';
import { DatePicker } from '@material-ui/pickers';
import useStyles from './styles';
import ModalBox from '../../components/ModalBox/ModalBox';
import { Formik } from 'formik';
import {
  Report,
  useCreateReportByFulfillmentMutation,
  useCreateReportByProductsMutation,
  useCreateReportByProductsTurnoverMutation,
  useCreateReportByShipmentsMutation,
  useCreateReportByTransactionsMutation,
  useGetReportsLazyQuery,
  useGetWarehousesListQuery,
  Warehouse,
} from '../../generated/graphql';
import { useSnackbar } from 'notistack';
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  LOCAL_STORAGE_KEYS,
  REPORT_TYPES,
} from '../../utils/constants';
import { LIMIT_ITEM_PAGE } from '../../utils/helpers';
import { useDebouncedCallback } from 'use-debounce/lib';
import FilterSelectLimitItems from '../../components/_Fields/FilterSelectLimitItems/FilterSelectLimitItems';
import { Pagination } from '@material-ui/lab';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { IFilters } from '../../utils/types';

interface IReportsFilter {
  page: number;
  itemsLimit: number;
}

const itemsLimit = localStorage.getItem(
  LOCAL_STORAGE_KEYS.ITEMS_LIMIT_SHIPMENTS_ALL_HISTORY,
);

const Reports = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpenReportsModal, setisOpenReportsModal] = useState(false);
  const [warehouseList, setWarehouseList] = useState<Warehouse[]>([]);
  const [type, setType] = useState('');
  const [reports, setReports] = useState<Report[] | null>(null);

  const { t } = useTranslation();

  const [filter, setFilter] = useState<IFilters>({
    page: 1,
    itemsLimit: itemsLimit ? +itemsLimit : LIMIT_ITEM_PAGE,
  });

  const { data: warehouses, error: warehousesError } =
    useGetWarehousesListQuery();

  const [
    getReportsLazyQuery,
    {
      loading: isLoadingGetReports,
      error: getReportsError,
      data: getReportsData,
    },
  ] = useGetReportsLazyQuery({ fetchPolicy: 'network-only' });

  const [
    createReportByShipments,
    {
      loading: isLoadingCreateReportByShipments,
      error: createReportByShipmentsError,
    },
  ] = useCreateReportByShipmentsMutation();

  const [
    createReportByTransactions,
    {
      loading: isLoadingCreateReportByTransactions,
      error: createReportByTransactionsError,
    },
  ] = useCreateReportByTransactionsMutation();

  const [
    createReportByProducts,
    {
      loading: isLoadingCreateReportByProducts,
      error: createReportByProductsError,
    },
  ] = useCreateReportByProductsMutation();
  const [
    createReportByFulfillment,
    {
      loading: isLoadingCreateReportByFulfillment,
      error: createReportByFulfillmentError,
    },
  ] = useCreateReportByFulfillmentMutation();

  const [
    createReportByProductsTurnover,
    {
      loading: isLoadingCreateReportProductsTurnover,
      error: createReportByProductsTurnoverError,
    },
  ] = useCreateReportByProductsTurnoverMutation();

  warehousesError?.message &&
    enqueueSnackbar(warehousesError.message, { variant: 'error' });
  createReportByShipmentsError?.message &&
    enqueueSnackbar(createReportByShipmentsError.message, { variant: 'error' });
  createReportByTransactionsError?.message &&
    enqueueSnackbar(createReportByTransactionsError.message, {
      variant: 'error',
    });
  createReportByProductsError?.message &&
    enqueueSnackbar(createReportByProductsError.message, { variant: 'error' });
  createReportByFulfillmentError?.message &&
    enqueueSnackbar(createReportByFulfillmentError.message, {
      variant: 'error',
    });
  getReportsError?.message &&
    enqueueSnackbar(getReportsError.message, {
      variant: 'error',
    });
  createReportByProductsTurnoverError?.message &&
    enqueueSnackbar(createReportByProductsTurnoverError.message, {
      variant: 'error',
    });

  useEffect(() => {
    if (!warehouses?.getWarehousesList?.rows) return;
    warehouses?.getWarehousesList?.rows &&
      setWarehouseList(warehouses?.getWarehousesList?.rows as Warehouse[]);
  }, [warehouses]);

  useEffect(() => {
    if (!getReportsData?.getReports?.rows) return;
    getReportsData?.getReports?.rows &&
      setReports(getReportsData?.getReports?.rows as Report[]);
  }, [getReportsData]);

  useEffect(() => {
    refetchReports();
    // eslint-disable-next-line
  }, [filter]);

  const getReportsText = (item: Report) => {
    if (item.type === 'PRODUCT')
      return `${t(REPORT_TYPES[item.type])} ${item.productBarcode}`;
    if (item.type === 'FULFILLMENT')
      return `${t(REPORT_TYPES[item.type])} ${item.warehouseCode}`;
    if (item.type) {
      const report = t(REPORT_TYPES[item.type]);
      if (report) return report;
    }
    return t('app.unknownReportType');
  };

  const refetchReports = useDebouncedCallback(() => {
    getReportsLazyQuery({
      variables: {
        offset: (filter.page! - 1) * filter.itemsLimit!,
        limit: filter.itemsLimit,
      },
    });
  }, 400);

  const handleDownloadReport = (fileToken: any) => {
    const token = localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
    if (!token) {
      enqueueSnackbar(t('app.unableToReadAuthToken'), {
        variant: 'error',
      });
      return;
    }

    fetch(`${process.env.REACT_APP_API_DOMAIN}/api/report/${fileToken}`, {
      headers: { authorization: `Bearer ${token}` },
    })
      .then((response) => {
        if (!response.ok) {
          enqueueSnackbar(response.statusText, { variant: 'error' });
          throw new Error(response.statusText);
        }

        return response.blob();
      })
      .then((response) => {
        let file = window.URL.createObjectURL(response);
        window.location.assign(file);
      })
      .catch((reason) => {
        reason?.message &&
          enqueueSnackbar(reason.message, { variant: 'error' });
      });
  };

  const handleCreateReport = (values: any) => {
    switch (type) {
      case 'SHIPMENTS':
        delete values.warehouseId;
        delete values.productId;
        createReportByShipments({
          variables: { input: values },
          onCompleted: (data) => {
            if (data) {
              enqueueSnackbar(t('app.reportSuccessfullyCreated'), {
                variant: 'success',
              });
            }
          },
        });
        break;
      case 'TRANSACTIONS':
        delete values.warehouseId;
        delete values.productId;
        createReportByTransactions({
          variables: { input: values },
          onCompleted: (data) => {
            if (data) {
              enqueueSnackbar(t('app.reportSuccessfullyCreated'), {
                variant: 'success',
              });
            }
          },
        });
        break;
      case 'PRODUCT':
        createReportByProducts({
          variables: { input: values },
          onCompleted: (data) => {
            if (data) {
              enqueueSnackbar(t('app.reportSuccessfullyCreated'), {
                variant: 'success',
              });
            }
          },
        });
        break;
      case 'FULFILLMENT':
        delete values.productId;
        createReportByFulfillment({
          variables: { input: values },
          onCompleted: (data) => {
            if (data) {
              enqueueSnackbar(t('app.reportSuccessfullyCreated'), {
                variant: 'success',
              });
            }
          },
        });
        break;
      case 'PRODUCT_TURNOVER':
        delete values.productId;
        createReportByProductsTurnover({
          variables: {
            dateFilter: { from: values.from, to: values.to },
            ...(values.warehouseId && { warehouseId: values.warehouseId }),
          },
          onCompleted: (data) => {
            if (data) {
              enqueueSnackbar(t('app.reportSuccessfullyCreated'), {
                variant: 'success',
              });
            }
          },
        });
        break;

      default:
        break;
    }
    setisOpenReportsModal(false);
    refetchReports();
  };

  return (
    <>
      <Typography variant='h2' align='center'>
        {t('app.reports').toUpperCase()}
      </Typography>

      <div className={classes.buttonsForSelected}>
        <FilterSelectLimitItems
          value={filter.itemsLimit as number}
          setFilter={setFilter}
          localStorageItemsLimitKey={
            LOCAL_STORAGE_KEYS.ITEMS_LIMIT_SHIPMENTS_ALL_HISTORY
          }
        />
        <Button
          variant='contained'
          onClick={() => {
            setisOpenReportsModal(true);
          }}
        >
          <LibraryBooksIcon className={classes.libraryIcon} />{' '}
          {t('app.createReport')}
        </Button>
      </div>

      <TableContainer component={Paper}>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell align='center'> {t('app.created')}</TableCell>
              <TableCell align='center'> {t('app.reportType')}</TableCell>
              <TableCell align='center'> {t('app.timeRange')}</TableCell>
              <TableCell align='center'> {t('app.download')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(!reports || isLoadingGetReports) && (
              <TableRow>
                <TableCell colSpan={4}>
                  <ShowLoadingText name='отчетов' />
                </TableCell>
              </TableRow>
            )}
            {reports && !isLoadingGetReports
              ? reports?.map(
                  (item) =>
                    item && (
                      <TableRow>
                        <TableCell align='center'>
                          {moment(Number(item.createdAt)).format(
                            DATE_TIME_FORMAT,
                          )}
                        </TableCell>
                        <TableCell align='center'>
                          {getReportsText(item)}
                        </TableCell>
                        <TableCell align='center'>
                          {moment(Number(item.from)).format(DATE_FORMAT)} -{' '}
                          {moment(Number(item.to)).format(DATE_FORMAT)}
                        </TableCell>
                        <TableCell align='center'>
                          <Tooltip title={t('app.clickToDownloadReport')}>
                            <GetAppIcon
                              onClick={() => {
                                handleDownloadReport(item.fileToken);
                              }}
                              className={classes.downloadIcon}
                            />
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ),
                )
              : !reports?.length &&
                !isLoadingGetReports && (
                  <TableRow>
                    <TableCell colSpan={4} align='center'>
                      {t('app.noRecords')}
                    </TableCell>
                  </TableRow>
                )}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        className={classes.pagination}
        count={
          getReportsData
            ? //@ts-ignore
              Math.ceil(getReportsData?.getReports?.count / filter.itemsLimit)
            : 1
        }
        page={filter.page!}
        onChange={(_, page) => {
          setFilter((state) => ({
            ...state,
            page: page,
          }));
        }}
        color='primary'
      />
      <ModalBox isOpen={isOpenReportsModal} setOpen={setisOpenReportsModal}>
        <Typography variant='h6' align='center'>
          {t('app.createReport')}
        </Typography>
        <Typography style={{ marginBottom: '12px' }}>
          {t('app.reportType')}
        </Typography>
        <Formik
          initialValues={{
            from: new Date(),
            to: new Date(),
            productId: '',
            warehouseId: 2,
          }}
          onSubmit={handleCreateReport}
        >
          {({ values, setFieldValue, handleSubmit }) => {
            return (
              <>
                <Select
                  className={classes.typeSelect}
                  id={'type'}
                  value={type}
                  onChange={(e) => {
                    //@ts-ignore
                    setType(e.target.value);
                    setFieldValue('warehouseId', 2);
                  }}
                >
                  {Object.entries(REPORT_TYPES).map(([key, value]) => {
                    return (
                      <MenuItem value={key} key={key}>
                        {t(value)}
                      </MenuItem>
                    );
                  })}
                </Select>
                <Typography style={{ marginBottom: '12px' }}>
                  {t('app.period')}
                </Typography>
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  style={{ marginBottom: '24px' }}
                >
                  <EventIcon fontSize='large' />
                  <DatePicker
                    className={classes.datePicker}
                    id='start_date'
                    value={values.from}
                    onChange={(date) => {
                      date && setFieldValue('from', date.toDate());
                    }}
                    {...{ maxDate: values.to }}
                    format='yyyy-MM-DD'
                  />
                  <Typography>{'-'}</Typography>

                  <DatePicker
                    id='to'
                    className={classes.datePicker}
                    value={values.to}
                    onChange={(date) => {
                      date && setFieldValue('to', date.toDate());
                    }}
                    {...{ maxDate: new Date() }}
                    format='yyyy-MM-DD'
                  />
                </Box>

                {type === 'PRODUCT' && (
                  <Box style={{ marginBottom: '24px' }}>
                    <InputLabel
                      className={classes.inputLabel}
                      shrink={false}
                      htmlFor={`productId-input`}
                    >
                      {t('app.productId')}
                    </InputLabel>
                    <Input
                      className={classes.input}
                      disableUnderline
                      id={`productId-input`}
                      value={values.productId}
                      onChange={(e) => {
                        setFieldValue('productId', e.target.value);
                      }}
                    />
                  </Box>
                )}

                {(type === 'PRODUCT' ||
                  type === 'FULFILLMENT' ||
                  type === 'PRODUCT_TURNOVER') && (
                  <FormControl variant='standard'>
                    <Typography style={{ marginBottom: '12px' }}>
                      {t('app.selectWarehouse')}
                    </Typography>
                    <Select
                      className={classes.typeSelect}
                      id='warehouse'
                      value={values.warehouseId}
                      onChange={(e) => {
                        setFieldValue('warehouseId', Number(e.target.value));
                      }}
                    >
                      <MenuItem
                        value={t('app.chooseWarehouse')}
                        disabled
                        style={{ display: 'none' }}
                      >
                        {t('app.chooseWarehouse')}
                      </MenuItem>
                      {type === 'PRODUCT_TURNOVER' && (
                        <MenuItem value={0}>{t('app.allWarehouses')}</MenuItem>
                      )}
                      {warehouseList?.map((item: any) => (
                        <MenuItem key={item.code} value={item.id}>
                          {item.code}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                <div className={classes.boxModalButtons}>
                  <Button
                    style={{ marginRight: '24px' }}
                    variant='contained'
                    onClick={() => {
                      setisOpenReportsModal(false);
                    }}
                  >
                    {t('app.cancel')}
                  </Button>
                  <Button
                    variant='contained'
                    onClick={() => {
                      handleSubmit();
                    }}
                    disabled={
                      isLoadingCreateReportByShipments ||
                      isLoadingCreateReportByTransactions ||
                      isLoadingCreateReportByProducts ||
                      isLoadingCreateReportByFulfillment ||
                      isLoadingCreateReportProductsTurnover
                    }
                    startIcon={<GetAppIcon />}
                  >
                    {t('app.create')}
                  </Button>
                </div>
              </>
            );
          }}
        </Formik>
      </ModalBox>
    </>
  );
};

export default Reports;
