import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import {
  ListItem,
  Typography,
  Box,
  Grid,
  IconButton,
  Button,
  Input,
  CircularProgress,
} from '@material-ui/core';
import PlaceIcon from '@material-ui/icons/Place';
import TodayIcon from '@material-ui/icons/Today';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import iconWarehouse from '../../../assets/img/icon_warehouse.png';

import { Link, useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { useTranslation } from 'react-i18next';
import RequestHandler from '../../../components/RequestHandler/RequestHandler';
import styles from './styles';
import {
  B2BOrder,
  B2BOrderCargoPlace,
  B2BOrderProduct,
  B2BShipmentMethodTypeEnum,
  B2BWrapTypeEnum,
  WarehouseB2BStatus,
  useConfirmAllB2BOrderBarodesUploadedMutation,
  useGetB2BOrderByIdQuery,
  useSetB2BOrderExternalIdMutation,
  useUploadB2BOrderBarcodesMutation,
  useUploadB2BOrderCargoPlaceBarcodeMutation,
  useUploadB2BOrderTransportFileMutation,
  useUploadB2BOrderTransportLabelMutation,
} from '../../../generated/graphql';
import TableList from '../../../components/TableList/TableList';
import { cargoPlacesHeadCells, productsHeadCells } from './options';
import { TRoutes } from '../../../utils/helpers';
import { BoxCentered } from '../../../components/BoxCentered/BoxCentered';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { TFunction } from 'i18next';
import {
  B2B_SHIPMENT_METHOD_BY_METHOD,
  COLORS,
  DATE_FORMAT,
  TIME_FORMAT,
  WAREHOUSE_B2B_STATUS_BY_STATUS,
} from '../../../utils/constants';
import moment from 'moment';
import SaveIcon from '@material-ui/icons/Save';
import { GET_B2B_ORDER_BY_ID_QUERY } from '../../../GraphQL/queries/getB2BOrderById';
import { TSetStateBoolean } from '../../../interfaces';
import B2bShipmentViewCargoPlaceModal, {
  B2BOrderProductWithCargoQuantity,
} from '../../../components/Modals/B2bShipmentViewCargoPlaceModal';

const createProductsData = (tableRowData: B2BOrderProduct) => {
  const relatedProduct = tableRowData.relatedProduct;
  const titleId = relatedProduct?.barcodeId?.split('.')[0];
  const photo = relatedProduct?.photo;
  return {
    id: tableRowData?.productId?.toString(),
    titleId,
    productSku: relatedProduct?.sku,
    productName: relatedProduct?.name,
    quantity: tableRowData?.expectedQuantity,
    photo: photo
      ? `${process.env.REACT_APP_API_DOMAIN}/api/getProductFile/${photo}`
      : null,
  };
};

const createData = (
  tableRowData: B2BOrderCargoPlace,
  t: TFunction<'translation', undefined>,
  handleFileChange: (
    cargoPlaceId: number,
    e: ChangeEvent<HTMLInputElement>,
  ) => void,
  id: number,
  setIsOpenModal: TSetStateBoolean,
  setCargoPlace: React.Dispatch<
    React.SetStateAction<B2BOrderCargoPlace | null>
  >,
  index: number,
  setCargoPlaceNumber: React.Dispatch<React.SetStateAction<number | null>>,
  uploadB2BOrderCargoPlaceBarcodeMutationLoading: boolean,
  type: B2BWrapTypeEnum,
  actionLink: string,
  fileName: any,
) => {
  const onChangeBarcode = (e: ChangeEvent<HTMLInputElement>) => {
    handleFileChange(Number(tableRowData?.id), e);
  };

  const uploadFileName = fileName?.find(
    (file: any) => file.cargoPlaceId === Number(tableRowData?.id),
  )?.fileName;

  return {
    type,
    uniqSkuCount: tableRowData.uniqSkuCount,
    productQty: tableRowData?.productQty,
    dimensions: `${tableRowData?.width}x${tableRowData?.length}x${
      tableRowData?.height
    } ${t('app.cm')}`,
    weight: `${tableRowData?.weight} ${t('app.kg')}`,
    barcode: uploadB2BOrderCargoPlaceBarcodeMutationLoading ? (
      <CircularProgress size={22} />
    ) : (
      <BoxCentered display={'flex'}>
        {tableRowData.externalBarcodeUrl ? (
          <>
            <Link
              className={actionLink}
              to={`/api/getBarcode/${tableRowData.externalBarcodeUrl}`}
              style={{
                display: 'block',
                width: 'auto',
              }}
              target='_blank'
            >
              {uploadFileName || tableRowData.externalBarcodeUrl.slice(0, -4)}
            </Link>
            <label
              htmlFor={`file-upload-${tableRowData.id}`}
              style={{ cursor: 'pointer' }}
            >
              <HighlightOffIcon
                style={{
                  width: 24,
                  height: 24,
                  color: COLORS.RED,
                  marginRight: '8px',
                }}
              />
            </label>
            <input
              id={`file-upload-${tableRowData.id}`}
              type='file'
              style={{ display: 'none' }}
              accept='.pdf'
              onChange={onChangeBarcode}
            />
          </>
        ) : (
          <>
            <label
              htmlFor={`file-upload-${tableRowData.id}`}
              style={{ cursor: 'pointer' }}
            >
              <Box display='flex'>
                {tableRowData.externalBarcodeUrl ? (
                  <Link
                    className={actionLink}
                    to={`/api/getBarcode/${tableRowData.externalBarcodeUrl}`}
                    target='_blank'
                  >
                    {uploadFileName ||
                      tableRowData.externalBarcodeUrl.slice(0, -4)}
                  </Link>
                ) : (
                  <Typography
                    style={{
                      color: COLORS.RED,
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                  >
                    {t('app.downloadBarcode')}
                  </Typography>
                )}
              </Box>
            </label>
            <input
              id={`file-upload-${tableRowData.id}`}
              type='file'
              style={{ display: 'none' }}
              accept='.pdf'
              onChange={onChangeBarcode}
            />
          </>
        )}
      </BoxCentered>
    ),
    viewCargoPlace: (
      <Link
        to='#'
        onClick={() => {
          setIsOpenModal(true);
          setCargoPlace(tableRowData);
          setCargoPlaceNumber(index);
        }}
      >
        {t('app.view')}
      </Link>
    ),
  };
};

const createCargoProductData = (
  tableRowData: B2BOrderProductWithCargoQuantity,
) => {
  const relatedProduct = tableRowData.relatedProduct;
  const titleId = relatedProduct?.barcodeId?.split('.')[0];
  return {
    id: tableRowData?.productId?.toString(),
    titleId,
    productSku: relatedProduct?.sku,
    productName: relatedProduct?.name,
    b2bCargoProductsQuantity: tableRowData.b2bCargoProductsQuantity,
  };
};

const B2bShipmentDetails = () => {
  const { t } = useTranslation();
  const classes = styles();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams<{ id: string }>();
  const [order, setOrder] = useState<B2BOrder | null>(null);
  const [externalId, setExternalId] = useState('');
  const history = useHistory();
  const [fileName, setFileName] = useState<any>([]);
  const [allBarcodesName, setAllBarcodesName] = useState('');
  const [passName, setPassName] = useState('');

  const [cargoPlaceProducts, setCargoPlaceProducts] = useState<
    B2BOrderProductWithCargoQuantity[] | null
  >(null);
  const [isOpenViewCargoPlaceModal, setisOpenViewCargoPlaceModal] =
    useState(false);
  const [cargoPlace, setCargoPlace] = useState<B2BOrderCargoPlace | null>(null);
  const [cargoPlaceNumber, setCargoPlaceNumber] = useState<number | null>(null);

  const { data, loading } = useGetB2BOrderByIdQuery({
    variables: {
      getB2BOrderByIdId: +id,
    },
    onError: (error) => {
      if (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    },
  });

  const [
    confirmAllB2BOrderBarodesUploadedMutation,
    { loading: confirmAllB2BOrderBarodesUploadedMutationLoading },
  ] = useConfirmAllB2BOrderBarodesUploadedMutation({
    onError: (error) => {
      if (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    },
  });

  const [
    uploadB2BOrderCargoPlaceBarcodeMutation,
    { loading: uploadB2BOrderCargoPlaceBarcodeMutationLoading },
  ] = useUploadB2BOrderCargoPlaceBarcodeMutation({
    onError: (error) => {
      if (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    },
  });

  const [
    uploadB2BOrderTransportFileMutation,
    { loading: uploadB2BOrderTransportFileMutationLoading },
  ] = useUploadB2BOrderTransportFileMutation({
    onError: (error) => {
      if (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    },
  });
  const [
    uploadB2BOrderTransportLabelMutation,
    { loading: uploadB2BOrderTransportLabelMutationLoading },
  ] = useUploadB2BOrderTransportLabelMutation({
    onError: (error) => {
      if (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    },
  });

  const [
    uploadB2BOrderBarcodesMutation,
    { loading: uploadB2BOrderBarcodesMutationLoading },
  ] = useUploadB2BOrderBarcodesMutation({
    onError: (error) => {
      if (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    },
  });

  const [
    setB2BOrderExternalIdMutation,
    { loading: setB2BOrderExternalIdMutationLoading },
  ] = useSetB2BOrderExternalIdMutation({
    onError: (error) => {
      if (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    },
  });

  useEffect(() => {
    if (cargoPlace && order) {
      setCargoPlaceProducts(
        cargoPlace?.productsItems?.map((product) => {
          const selectedProduct = order?.products?.find(
            (item) => item?.productId === product?.productId,
          );
          return {
            ...selectedProduct,
            b2bCargoProductsQuantity: product?.quantity,
          };
        }) as B2BOrderProductWithCargoQuantity[],
      );
    }
    //eslint-disable-next-line
  }, [cargoPlace, order]);

  useEffect(() => {
    if (data) {
      setOrder(data?.getB2BOrderById as B2BOrder);
      setExternalId(data?.getB2BOrderById?.externalOrdeId || '');
    }
  }, [data]);

  const handleComplete = () => {
    confirmAllB2BOrderBarodesUploadedMutation({
      variables: { orderId: Number(id) },
      onCompleted: (data) => {
        if (data) {
          enqueueSnackbar(t('app.barcodesSuccessfullyDownloaded'), {
            variant: 'success',
          });
          history.push(TRoutes.B2B_ORDERS);
        }
      },
      refetchQueries: [GET_B2B_ORDER_BY_ID_QUERY],
    });
  };

  const handleBarcodeDownload = (
    cargoPlaceId: number,
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      setFileName((prev: any) => [
        ...prev,
        { cargoPlaceId, fileName: file?.name.slice(0, -4) },
      ]);
      uploadB2BOrderCargoPlaceBarcodeMutation({
        variables: {
          orderId: Number(id),
          cargoPlaceId,
          barcode: file,
        },
        onCompleted: (data) => {
          if (data) {
            enqueueSnackbar(t('app.barcodeSuccessfullyDownloaded'), {
              variant: 'success',
            });
          }
        },
        refetchQueries: [GET_B2B_ORDER_BY_ID_QUERY],
      });
    }
  };

  const handleAllBarcodesDownload = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      setAllBarcodesName(file?.name.slice(0, -4));
      uploadB2BOrderBarcodesMutation({
        variables: {
          orderId: Number(id),
          barcodes: file,
        },
        onCompleted: (data) => {
          if (data) {
            enqueueSnackbar(t('app.barcodesSuccessfullyDownloaded'), {
              variant: 'success',
            });
          }
        },
        refetchQueries: [GET_B2B_ORDER_BY_ID_QUERY],
      });
    }
  };

  const handleSaveExternalOrderID = () => {
    setB2BOrderExternalIdMutation({
      variables: { orderId: Number(id), externalId: externalId },
      onCompleted: (data) => {
        if (data) {
          enqueueSnackbar(t('app.externalIdSuccessfullySaved'), {
            variant: 'success',
          });
        }
      },
      refetchQueries: [GET_B2B_ORDER_BY_ID_QUERY],
    });
  };

  const handleDownloadTransport = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (!file) return;
    setPassName(file.name.slice(0, -4));
    if (
      order?.shipmentInfo?.typeMethod === B2BShipmentMethodTypeEnum.PicknPack
    ) {
      uploadB2BOrderTransportFileMutation({
        variables: {
          orderId: Number(id),
          file,
        },
        onCompleted: (data) => {
          if (data) {
            enqueueSnackbar(t('app.transportFileSuccessfullyDownloaded'), {
              variant: 'success',
            });
          }
        },
        refetchQueries: [GET_B2B_ORDER_BY_ID_QUERY],
      });
    } else {
      uploadB2BOrderTransportLabelMutation({
        variables: {
          orderId: Number(id),
          label: file,
        },
        onCompleted: (data) => {
          if (data) {
            enqueueSnackbar(t('app.transportLabelSuccessfullyDownloaded'), {
              variant: 'success',
            });
          }
        },
        refetchQueries: [GET_B2B_ORDER_BY_ID_QUERY],
      });
    }
  };

  const rowsProducts =
    order?.products?.map((tableRowData) =>
      createProductsData(tableRowData as B2BOrderProduct),
    ) || [];

  const rows = useMemo(
    () =>
      order?.cargoPlaces?.map((tableRowData, index) =>
        createData(
          tableRowData as B2BOrderCargoPlace,
          t,
          handleBarcodeDownload,
          Number(id),
          setisOpenViewCargoPlaceModal,
          setCargoPlace,
          index,
          setCargoPlaceNumber,
          uploadB2BOrderCargoPlaceBarcodeMutationLoading,
          order.wrapType as B2BWrapTypeEnum,
          classes.actionLink,
          fileName,
        ),
      ) || [],
    // eslint-disable-next-line
    [order],
  );

  const rowsCargoProducts =
    cargoPlaceProducts?.map((tableRowData) =>
      createCargoProductData(tableRowData as B2BOrderProductWithCargoQuantity),
    ) || [];

  const formattedDate = moment(Number(order?.createdAt)).format(DATE_FORMAT);

  const formattedSceduledDate = new Date(
    order?.shipmentInfo?.scheduledDate as string,
  ).toLocaleDateString();

  const formattedSceduledTime = moment(
    order?.shipmentInfo?.scheduledTime,
  ).format(TIME_FORMAT);

  const shipmentIdTitle = order?.barcodeId?.split('.')[0];

  const isAllBarcodesDownloaded =
    !!order?.cargoPlaces?.length &&
    !order?.cargoPlaces?.some((place) => !place?.externalBarcodeUrl);

  const totalCost = order?.servicesWithCost?.reduce((acc, service) => {
    return acc + Number(service?.costInUSD) * Number(service?.qty);
  }, 0);

  return (
    <RequestHandler loading={loading}>
      <>
        <Box mb={4}></Box>
        <Typography variant='h2' align='center' className={classes.mainTitle}>
          {t('app.viewOrder')}
        </Typography>
        <ListItem>
          <Typography variant='h3'>
            {t('app.orderNumber')}: {shipmentIdTitle}
          </Typography>
        </ListItem>
        <ListItem className={classes.itemInfo}>
          {`${t('app.creationDate')}: ${formattedDate}`}
        </ListItem>
        <ListItem className={classes.itemInfo}>
          {`${t('app.orderStatus')}: ${t(
            WAREHOUSE_B2B_STATUS_BY_STATUS[order?.warehouseStatus as string],
          )}`}
        </ListItem>
        <ListItem className={classes.itemInfo}>
          {`${t('app.externalOrdeId')}`}
          <Input
            style={{ marginLeft: '12px' }}
            placeholder={t('app.enterText')}
            className='filtersSearchField'
            onChange={(e) => {
              setExternalId(e.target.value);
            }}
            value={externalId}
            disableUnderline
          />
          <Button
            style={{ marginLeft: '12px' }}
            variant='contained'
            type='button'
            aria-label='search'
            disabled={setB2BOrderExternalIdMutationLoading}
            onClick={handleSaveExternalOrderID}
          >
            {t('app.save')}
          </Button>
        </ListItem>

        <Grid
          container
          spacing={0}
          alignItems='stretch'
          style={{ marginBottom: '24px', marginTop: '24px' }}
        >
          <Grid item sm={6} xs={12}>
            <ListItem className={classes.itemInfo}>
              <BoxCentered mr={2}>
                <img
                  src={iconWarehouse}
                  alt='barcode'
                  style={{ width: 35, height: 35 }}
                />
              </BoxCentered>
              <Box>
                <Typography variant='h3'>{t('app.warehouse')}</Typography>
                <Box>{order?.warehouseName}</Box>
              </Box>
            </ListItem>
            <ListItem className={classes.itemInfo}>
              <BoxCentered mr={2}>
                <PlaceIcon style={{ width: 35, height: 35 }} />
              </BoxCentered>
              <Box>
                <Typography variant='h3'>
                  {t('app.b2bShipmentsPoint')}
                </Typography>
                <Box>{order?.shipmentPointTitle}</Box>
              </Box>
            </ListItem>
          </Grid>

          <Grid item sm={6} xs={12}>
            <ListItem className={classes.itemInfo}>
              <BoxCentered mr={2}>
                <TodayIcon style={{ width: 35, height: 35 }} />
              </BoxCentered>
              <Box>
                <Typography variant='h3'>{t('app.shipmentDate')}</Typography>
                <Box>{`${formattedSceduledDate} ${formattedSceduledTime}`}</Box>
              </Box>
            </ListItem>
            <ListItem className={classes.itemInfo}>
              <BoxCentered mr={2}>
                <LocalShippingIcon style={{ width: 35, height: 35 }} />
              </BoxCentered>
              <Box>
                <Typography variant='h3'>{t('app.shipmentMethod')}</Typography>
                <Box>{`${t(
                  B2B_SHIPMENT_METHOD_BY_METHOD[
                    order?.shipmentInfo?.typeMethod as string
                  ],
                )}`}</Box>
                {order?.shipmentInfo?.typeMethod ===
                  B2BShipmentMethodTypeEnum.PicknPack && (
                  <>
                    {order?.shipmentInfo?.driverInfo?.fullName &&
                      order?.shipmentInfo?.driverInfo?.mobileNumber && (
                        <Box>{`${order?.shipmentInfo?.driverInfo?.fullName} ${order?.shipmentInfo?.driverInfo?.mobileNumber}`}</Box>
                      )}
                    {order?.shipmentInfo?.driverInfo?.carModel &&
                      order?.shipmentInfo?.driverInfo?.carNumber && (
                        <Box>{`${order?.shipmentInfo?.driverInfo?.carModel} ${order?.shipmentInfo?.driverInfo?.carNumber}`}</Box>
                      )}
                  </>
                )}
              </Box>
            </ListItem>
          </Grid>
        </Grid>
        <Box pb={1} pl={2} mt={7}>
          <Typography variant='h6'>{t('app.listOfItems')}</Typography>
        </Box>
        <Box>
          {rowsProducts.length ? (
            <TableList headCells={productsHeadCells} rows={rowsProducts} />
          ) : null}
        </Box>
        {order?.comment && (
          <ListItem className={classes.itemInfo}>
            {`${t('app.comment')}: ${order?.comment}`}
          </ListItem>
        )}
        {order?.technicalTaskFileUrl ? (
          <Link
            className={classes.actionLink}
            style={{ marginBottom: '24px' }}
            to={`/api/getAttachment/${order?.technicalTaskFileUrl}`}
            target='_blank'
          >
            <IconButton component='span' style={{ padding: 0 }}>
              <AttachFileIcon />
            </IconButton>
            {t('app.task')}
          </Link>
        ) : null}

        {rows.length ? (
          <>
            <Box pb={1} pl={2} mt={4}>
              <Typography variant='h6'>{t('app.cargoplaces')}</Typography>
            </Box>
            <TableList headCells={cargoPlacesHeadCells} rows={rows} />
            <Box
              mt={2}
              ml={2}
              mr={2}
              display={'flex'}
              justifyContent={'space-between'}
            >
              <BoxCentered>
                {uploadB2BOrderTransportFileMutationLoading ||
                uploadB2BOrderTransportLabelMutationLoading ? (
                  <CircularProgress size={22} />
                ) : order?.transportFileUrl || order?.shipmentLabelFileUrl ? (
                  <>
                    <label
                      htmlFor='file-upload-transport'
                      style={{ cursor: 'pointer', color: COLORS.BLUE }}
                    >
                      <IconButton component='span' style={{ padding: 0 }}>
                        <AttachFileIcon />
                      </IconButton>
                    </label>
                    <input
                      id='file-upload-transport'
                      type='file'
                      style={{ display: 'none' }}
                      accept='.pdf'
                      onChange={handleDownloadTransport}
                    />
                    <Link
                      className={classes.actionLink}
                      to={`/api/getAttachment/${
                        order?.transportFileUrl || order?.shipmentLabelFileUrl
                      }`}
                      target='_blank'
                    >
                      {passName ||
                        order?.shipmentLabelFileUrl?.slice(0, -4) ||
                        order?.transportFileUrl?.slice(0, -4)}
                    </Link>
                  </>
                ) : (
                  <>
                    <label
                      htmlFor='file-upload-transport'
                      style={{ cursor: 'pointer', color: COLORS.BLUE }}
                    >
                      <IconButton component='span' style={{ padding: 0 }}>
                        <AttachFileIcon />
                      </IconButton>
                      {order?.shipmentInfo?.typeMethod ===
                      B2BShipmentMethodTypeEnum.PicknPack
                        ? t('app.downloadTransportFile')
                        : t('app.downloadTransportLabel')}
                    </label>
                    <input
                      id='file-upload-transport'
                      type='file'
                      style={{ display: 'none' }}
                      accept='.pdf'
                      onChange={handleDownloadTransport}
                    />
                  </>
                )}
              </BoxCentered>
              <BoxCentered>
                {uploadB2BOrderBarcodesMutationLoading ? (
                  <CircularProgress size={22} />
                ) : (
                  <>
                    {order?.externalBarcodesUrl ? (
                      <>
                        <Link
                          className={classes.actionLink}
                          to={`/api/getBarcode/${order?.externalBarcodesUrl}`}
                          style={{
                            display: 'block',
                            width: 'auto',
                          }}
                          target='_blank'
                        >
                          {allBarcodesName ||
                            order?.externalBarcodesUrl.slice(0, -4)}
                        </Link>
                        <label
                          htmlFor={`file-upload-all-barcodes`}
                          style={{ cursor: 'pointer' }}
                        >
                          <HighlightOffIcon
                            style={{
                              width: 24,
                              height: 24,
                              color: COLORS.RED,
                              marginRight: '8px',
                            }}
                          />
                        </label>
                        <input
                          id={`file-upload-all-barcodes`}
                          type='file'
                          style={{ display: 'none' }}
                          accept='.pdf'
                          onChange={handleAllBarcodesDownload}
                        />
                      </>
                    ) : order?.externalBarcodesUrl ? (
                      <Link
                        className={classes.actionLink}
                        to={`/api/getBarcode/${order?.externalBarcodesUrl}`}
                      >
                        {allBarcodesName ||
                          order?.externalBarcodesUrl.slice(0, -4)}
                      </Link>
                    ) : (
                      <>
                        <label
                          htmlFor={`file-upload-all-barcodes`}
                          style={{ cursor: 'pointer' }}
                        >
                          <Box display='flex'>
                            <Typography
                              style={{
                                color: COLORS.RED,
                                textDecoration: 'underline',
                                cursor: 'pointer',
                              }}
                            >
                              {t('app.downloadBarcodes')}
                            </Typography>
                          </Box>
                        </label>
                        <input
                          id={`file-upload-all-barcodes`}
                          type='file'
                          style={{ display: 'none' }}
                          accept='.pdf'
                          onChange={handleAllBarcodesDownload}
                        />
                      </>
                    )}
                  </>
                )}
              </BoxCentered>
            </Box>
          </>
        ) : null}

        {!!totalCost ? (
          <>
            <Box pb={1} pl={2} mt={7}>
              <Typography variant='h6'>{t('app.cost')}</Typography>
            </Box>
            {order?.servicesWithCost?.map((service) => {
              const totalItemCost =
                Number(service?.costInUSD) * Number(service?.qty);
              return totalItemCost ? (
                <ListItem className={classes.itemInfo}>
                  {`${service?.title}: ${totalItemCost.toFixed(2)} (${
                    service?.qty
                  })`}
                </ListItem>
              ) : null;
            })}
            <ListItem
              className={classes.itemInfo}
              style={{ fontWeight: 'bold' }}
            >
              {t('app.overall')} {totalCost.toFixed(2)}
            </ListItem>
          </>
        ) : null}

        <div className={classes.wrapButtons}>
          <Button
            type={'submit'}
            variant='contained'
            disabled={
              confirmAllB2BOrderBarodesUploadedMutationLoading ||
              !isAllBarcodesDownloaded ||
              order.warehouseStatus === WarehouseB2BStatus.ShipmentReady ||
              uploadB2BOrderTransportFileMutationLoading ||
              uploadB2BOrderTransportLabelMutationLoading ||
              uploadB2BOrderBarcodesMutationLoading
            }
            startIcon={<SaveIcon />}
            onClick={handleComplete}
          >
            {t('app.toConfirmBarcodes')}
          </Button>
        </div>
        <B2bShipmentViewCargoPlaceModal
          isOpenModal={isOpenViewCargoPlaceModal}
          setIsOpenModal={setisOpenViewCargoPlaceModal}
          cargoPlace={cargoPlace}
          cargoPlaceNumber={cargoPlaceNumber}
          rowsProducts={rowsCargoProducts}
        />
      </>
    </RequestHandler>
  );
};

export default B2bShipmentDetails;
