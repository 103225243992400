/* eslint-disable array-callback-return */
import React from 'react';
import { useDynamicScripts, useGlobalSettings } from '../hooks';
import { useGetWhiteLabelConfigQuery } from '../generated/graphql';
import { getLebalFileUrl } from '../utils/getLebalFileUrl';

type Props = {
  children: React.ReactNode;
};
export const SettingsProvider = ({ children }: Props) => {
  const { data } = useGetWhiteLabelConfigQuery();

  const appTitle = data?.getWhiteLabelConfig?.brandName;
  const favIconPath = data?.getWhiteLabelConfig?.favIconPath;
  const script = data?.getWhiteLabelConfig?.scriptsJSON;

  useGlobalSettings({
    title: appTitle ? `${appTitle}` : '',
    faviconUrl: getLebalFileUrl(favIconPath || ''),
  });

  useDynamicScripts({
    scriptHTML: script ? JSON.parse(script) : '',
  });

  return (
    <SettingsContext.Provider value={{}}>{children}</SettingsContext.Provider>
  );
};

type ContextProps = {};

export const SettingsContext = React.createContext<ContextProps>({});

export const useSettingsContext = () => {
  const context = React.useContext(SettingsContext);
  if (!context) {
    throw new Error(
      'useSettingsContext must be used within a SettingsProvider',
    );
  }
  return context;
};
