import {
  GetMyProductListQuery,
  GetOrderByIdQuery,
  ParcelContact,
} from '../../../../../generated/graphql';
import {
  CURRENCIES,
  PARCEL_FIELDS,
  VATKEY,
} from '../../../../../utils/constants';
import { getInitialUser } from '../../../../../utils/helpers';

type createInitValueType = {
  orderByIdData: GetOrderByIdQuery | undefined;
  urlSearchParams: URLSearchParams;
  cachedProducts: GetMyProductListQuery | null;
  allCountries: any;
};

export const createShipmentFromWarehouseInitValue = ({
  urlSearchParams,
  cachedProducts,
  orderByIdData,
  allCountries,
}: createInitValueType) => {
  const order = orderByIdData?.getOrderById;
  const myProducts = cachedProducts?.getMyProductList?.rows;
  const productsSku = order?.orderProducts?.map(
    //@ts-ignore
    (product: OrderProduct) => product.sku,
  );

  const marketPlaceProducts = myProducts?.filter(
    //@ts-ignore
    (product) => product?.sku && productsSku?.indexOf(product?.sku) !== -1,
  );

  const initialValues = !productsSku?.length
    ? {
        ...initialShipments,
        receiver: {
          ...(getInitialUser(
            urlSearchParams.get(PARCEL_FIELDS.RECEIVER.NAME),
            urlSearchParams.get(PARCEL_FIELDS.RECEIVER.COMPANY),
            urlSearchParams.get(PARCEL_FIELDS.RECEIVER.COUNTRY),
            urlSearchParams.get(PARCEL_FIELDS.RECEIVER.ADDRESS),
            urlSearchParams.get(PARCEL_FIELDS.RECEIVER.ADDRESS2),
            urlSearchParams.get(PARCEL_FIELDS.RECEIVER.ADDRESS3),
            urlSearchParams.get(PARCEL_FIELDS.RECEIVER.ZIP_CODE),
            urlSearchParams.get(PARCEL_FIELDS.RECEIVER.CITY),
            urlSearchParams.get(PARCEL_FIELDS.RECEIVER.STATE),
            urlSearchParams.get(PARCEL_FIELDS.RECEIVER.PHONE),
            urlSearchParams.get(PARCEL_FIELDS.RECEIVER.EMAIL),
          ) as ParcelContact),
          country: allCountries.find(
            (city: any) =>
              city.name === urlSearchParams.get(PARCEL_FIELDS.RECEIVER.COUNTRY),
          ),
        },
      }
    : {
        ...initialShipments,
        //@ts-ignore
        currency: order?.orderProducts[0]?.priceCurrency?.toLowerCase(),
        ...(order && {
          senderMark: order?.orderId,
        }),
        receiver: {
          ...(getInitialUser(
            urlSearchParams.get(PARCEL_FIELDS.RECEIVER.NAME),
            urlSearchParams.get(PARCEL_FIELDS.RECEIVER.COMPANY),
            urlSearchParams.get(PARCEL_FIELDS.RECEIVER.COUNTRY),
            urlSearchParams.get(PARCEL_FIELDS.RECEIVER.ADDRESS),
            urlSearchParams.get(PARCEL_FIELDS.RECEIVER.ADDRESS2),
            urlSearchParams.get(PARCEL_FIELDS.RECEIVER.ADDRESS3),
            urlSearchParams.get(PARCEL_FIELDS.RECEIVER.ZIP_CODE),
            urlSearchParams.get(PARCEL_FIELDS.RECEIVER.CITY),
            urlSearchParams.get(PARCEL_FIELDS.RECEIVER.STATE),
            urlSearchParams.get(PARCEL_FIELDS.RECEIVER.PHONE),
            urlSearchParams.get(PARCEL_FIELDS.RECEIVER.EMAIL),
          ) as ParcelContact),
          country: allCountries.find(
            (city: any) =>
              city.name === urlSearchParams.get(PARCEL_FIELDS.RECEIVER.COUNTRY),
          ),
        },
        products: marketPlaceProducts?.map((product) => ({
          id: Number(product?.id),
          code: product?.code,
          countryId: product?.country?.id,
          nameEN: product?.detailsDescriptionEN,
          price: product?.declaredValue,
          quantity: 1,
          photo: product?.photo,
          weight: product?.netWeight,
        })),
        selected: marketPlaceProducts?.map((product) => ({
          maxQuantity: product?.productAvailabilityQuantity?.availableQuantity,
          product: { ...product },
        })),
      };

  return initialValues;
};

const initialShipments = {
  products: [{ id: 0, quantity: 1, price: 0 }],
  selected: [],
  additionalInsurance: false,
  insuranceAmount: 0,
  signature: false,
  senderMark: '',
  typeDeliveryId: 1,
  carrierService: '',
  currency: CURRENCIES.USD.toLowerCase(),
  wrap: 'DEFAULT',
  message: '',
  comment: '',
  vatKey: VATKEY.EMPTY,
  vatValue: '',
  orderCostCurrency: '',
  preliminaryCost: '',
  giftWrap: false,
};
