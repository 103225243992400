import { useEffect } from 'react';
// TODO new tasks dynamic scripts

type Props = {
  scriptHTML: string;
};

export const useDynamicScripts = ({ scriptHTML }: Props) => {
  useEffect(() => {
    if (!scriptHTML) return;
    const parser = new DOMParser();
    const doc = parser.parseFromString(scriptHTML, 'text/html');

    const appendedScripts: Array<HTMLScriptElement> = [];

    const scripts = doc.querySelectorAll('script');

    scripts.forEach((scriptTag) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';

      if (scriptTag.src) {
        script.src = scriptTag.src;
        script.async = true;
      } else {
        script.text = scriptTag.innerHTML;
      }

      document.body.appendChild(script);
      appendedScripts.push(script);
    });

    return () => {
      appendedScripts.forEach((script) => {
        document.body.removeChild(script);
      });
    };
  }, [scriptHTML]);
};
