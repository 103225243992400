export const MAX_PARCEL_WEIGHT = 150;
export const MAX_PARCEL_DIMENSION = 120;

export const DATE_FORMAT = 'DD.MM.YYYY';
export const TIME_FORMAT = 'HH:mm';
export const DATE_TIME_FORMAT = 'DD.MM.YYYY H:mm:ss';

export const ERRORS = {
  CANT_CANCEL_PARCEL_WITH_IS_SENT: 'app.cannotCancelOrder',
  FIELD_REQUIRED: 'app.fillInTheField',
  POSITIVE_NUMBER: 'app.positiveNumber',
  MAX_WEIGHT: 'app.maxWeightError',
  MAX_DIMENSION: 'app.maxDimensionsError',
  MAX_SYMBOLS: 'app.maxCharacters',
  MAX_VALUE: 'app.maxValue',
  MAX_QUANTITY: 'app.maxQuantity',
};

export const TEXT = {
  GROSS: 'app.grossDefinition',
  NET: 'app.netDefinition',
  CLICK_TO_MAKE_A_CALL: 'app.clickToDial',
  CLICK_TO_SEND_AN_EMAIL: 'app.clickToSendEmail',
  SERVICE_IS_NOT_AVAILABLE_YET: 'app.integrationNotImplemented',
};

export const COLORS = {
  GREEN: '#048c04',
  BLUE: '#0b82f7',
  RED: '#ff0000',
  YELLOW: '#ffc749',
  ORANGE: '#ff6e00',
  GREY: '#808080',
  WHITE: '#ffffff',
  BLACK: '#000000',
  LIGHT_BLACK: '#444444',
  DARK_GREY: '#606060',
  LIGHT_GREY: '#F3F3F3',
  MEDIUM_GREY: '#E6E6E6',
  DOGHUNT_GREY: '#6b6c6d',
  DOGHUNT_YELLOW: '#ffc030',
  DOGHUNT_GREEN: '#368314',
  DOGHUNT_RED: '#981a1a',
  DOGHUNT_BLACK: '#2d2d2d',
};

export const PAYMENT_TYPES: { [key: string]: string } = {
  REFILL: 'app.accountReplenishment',
  REFUND: 'app.refund2',
  ORDER_PAYMENT: 'app.debitForOrder',
  CDEK: 'app.shipmentToWarehouseViaCDEK',
  CANCEL_ADJUSTMENT: 'app.cancelWeightCorrection',
  WEIGHT_ADJUSTMENT: 'app.weightCorrection',
  WAREHOUSE_STORAGE: 'app.storageOnWarehouse',
  SERVICE_FEE: 'app.serviceFee',
};
export const MOVEMENT_OPERATIONS: { [key: string]: string } = {
  REFILL: 'app.refill2',
  WRITE_OFF: 'app.deduction',
  INTERNAL_SWAP: 'app.internalSwap',
  SENDING: 'app.sending',
  ORDER_PICKING: 'app.orderPicking',
  ORDER_CANCEL: 'app.cancel',
  RETURN: 'app.refund',
  UNDECLARED_INBOUND: 'app.undeclaredArrival',
  INBOUND: 'app.inbound',
};

export const REPORT_TYPES: { [key: string]: string } = {
  TRANSACTIONS: 'app.transactionReport',
  PRODUCT: 'app.productReport',
  FULFILLMENT: 'app.fulfillmentReport',
  PRODUCT_TURNOVER: 'app.productsTurnoverReport',
};

// SEND TO WAREHOUSE TARIFF CODES
export const WAREHOUSE_TARIFFS = {
  // Посылка склад-склад
  CDEK_WAREHOUSE: '136',
  // Посылка дверь-склад
  CDEK_DOOR: '138',
  // Магистральный экспресс склад-склад
  CDEK_MAINLINE_EXPRESS_WAREHOUSE: '62',
  // Магистральный супер-экспресс склад-склад
  CDEK_MAINLINE_SUPER_EXPRESS_WAREHOUSE: '63',
  // Самостоятельно
  SELF_EXPORT: '999',
};

export const CURRENCIES = {
  RUB: 'RUB',
  EUR: 'EUR',
  USD: 'USD',
  GBP: 'GBP',
};

export const COUNTRIES_CIS = [
  {
    iso: 'AZ',
    name: 'Азербайджанская Республика',
    nameEng: 'Azerbaijan Republic',
    id: 248,
  },
  {
    iso: 'AM',
    name: 'Республика Армения',
    nameEng: 'Respublika Armenia',
    id: 244,
  },
  {
    iso: 'BY',
    name: 'Республика Беларусь',
    nameEng: 'Republic of Belarus',
    id: 253,
  },
  {
    iso: 'KZ',
    name: 'Республика Казахстан',
    nameEng: 'Republic of Kazakhstan',
    id: 339,
  },
  { iso: 'KG', name: 'Кыргызская Республика', nameEng: 'Kyrgyzstan', id: 345 },
  { iso: 'MD', name: 'Республика Молдова', nameEng: 'Moldova', id: 369 },
  { iso: 'RU', name: 'Россия', nameEng: 'Russia', id: 459 },
  { iso: 'TJ', name: 'Республика Таджикистан', nameEng: 'Tajikistan', id: 432 },
  { iso: 'TM', name: 'Туркменистан', nameEng: 'Turkmenistan', id: 441 },
  { iso: 'UZ', name: 'Республика Узбекистан', nameEng: 'Uzbekistan', id: 450 },
  { iso: 'UA', name: 'Украина', nameEng: 'Ukraine', id: 445 },
];

export const LOCAL_STORAGE_KEYS = {
  AUTH_TOKEN: 'authToken',
  CDEK_REGIONS: 'CDEKRegions',
  CDEK_CITIES: 'CDEKCities',
  ITEMS_LIMIT_SHIPMENTS_ALL_HISTORY: 'itemsLimit',
  WAREHOUSE_ID: 'warehouseId',
  WAREHOUSE_ID_ORDER: 'warehouseIdOrder',
  ITEMS_LIMIT_MARKETPLACE_ORDERS: 'itemsLimitMarketplaceOrders',
  ITEMS_LIMIT_RETURNS: 'itemsLimitReturns',
  ITEMS_LIMIT_NOTIFICATIONS: 'itemsLimitNotifications',
  IS_SIDEBAR_HIDDEN: 'isSidebarHidden',
  IS_LIST_OPEN_SENDING: 'isListOpenSending',
  IS_LIST_OPEN_PRODUCTS_AND_WAREHOUSE: 'isListOpenProductsAndWarehouse',
  IS_LIST_OPEN_ORDERS: 'isListOpenOrders',
  IS_LIST_OPEN_RETURNS: 'isListOpenReturns',
  IS_LIST_OPEN_INTEGRATION: 'isListOpenIntegration',
  IS_LIST_OPEN_SENDING_TO_WAREHOUSE: 'isListOpenSendingToWarehouse',
  IS_LIST_REPORTS: 'isListOpenReports',
  DELIVERY_COUNTRIES: 'deliveryCountries',
  TAB_VALUE: 'tabValue',
  CURRENT_APP_CURRENCY: 'currentAppCurrency',
};

export const UNIT_STATES = {
  UNKNOWN: 'UNKNOWN',
  NEW: 'NEW',
  USED: 'USED',
};

export const EXPORT_REASONS = {
  COMMERCIAL: { en: 'COMMERCIAL', ru: 'Продажа' },
  GIFT: { en: 'GIFT', ru: 'Подарок' },
  SAMPLE: { en: 'SAMPLE', ru: 'Образец' },
  RETURN: { en: 'RETURN', ru: 'Возврат' },
  REPAIR: { en: 'REPAIR', ru: 'Возврат для ремонта' },
};

export const PAYMENT_OF_TAXES = {
  RECIPIENT: { en: 'RECIPIENT', ru: 'Получатель' },
  SENDER: { en: 'SENDER', ru: 'Отправитель' },
};

export const PAYMENT_OF_TAXES_IMPORT = {
  CONSIGNEE: { en: 'CONSIGNEE', ru: 'Получатель' },
  SENDER: { en: 'SENDER', ru: 'Отправитель' },
};

export const VATKEY = {
  VAT: 'VAT',
  IOSS: 'IOSS',
  EIN: 'EIN',
  EMPTY: 'EMPTY', //default
};

export const MARKETPLACES = {
  AMAZON: 'amazon',
  EBAY: 'ebay',
  ETSY: 'etsy',
};

export const PARCEL_FIELDS = {
  RECEIVER: {
    NAME: 'receiverName',
    COMPANY: 'receiverCompany',
    COUNTRY: 'receiverCountry',
    ADDRESS: 'receiverAddress',
    ADDRESS2: 'receiverAddress2',
    ADDRESS3: 'receiverAddress3',
    ZIP_CODE: 'receiverZipCode',
    CITY: 'receiverCity',
    STATE: 'receiverState',
    PHONE: 'receiverPhone',
    EMAIL: 'receiverEmail',
  },
  PACKAGE: {
    WEIGHT: 'packageWeight',
    LENGTH: 'packageLength',
    WIDTH: 'packageWidth',
    HEIGHT: 'packageHeight',
  },
  OTHER: {
    ID: 'id',
  },
};

export const DEBOUNCE = 10;

export const WRAP_TYPES = {
  DEFAULT: 'app.defaultPackage',
  BUBBLE: 'app.bubbleWrap',
  BOX_S: 'app.smallBox',
  BOX_M: 'app.mediumBox',
  BOX_L: 'app.largeBox',
};

export enum identifiersType {
  MARKETPLACE_NUMBER = 'app.marketplaceNumber',
}

export enum warehouseStatuses {
  CREATED = 'app.created',
  PROCESSING = 'app.receivingInProgress',
  ACCEPTED = 'app.received',
  CANCELED = 'app.canceled',
  EXPIRED = 'app.expired',
  ACCEPTED_WITH_DISCREPANCY = 'app.acceptedWithDiscrepancy',
}

export const DELIVERY_STATUS_BY_ID: { [key: number]: string } = {
  1: 'app.created',
  3: 'app.inTransit',
  4: 'app.accepted',
  5: 'app.delivered',
  7: 'app.exception',
  9: 'app.canceled',
};

export const PAYMENT_ORDER_STATUS_BY_ID: { [key: number]: string } = {
  1: 'app.awaitingPayment',
  2: 'app.paid',
  3: 'app.partialPayment',
  4: 'app.refund',
  5: 'app.notRequired',
};

export const WAREHOUSE_SHIPMENT_STATUS_BY_ID: { [key: number]: string } = {
  1: 'app.awaitingPayment',
  2: 'app.paid',
  3: 'app.partialPayment',
  4: 'app.refund',
  5: 'app.notRequired',
};

export const WAREHOUSE_B2B_STATUS_BY_STATUS: { [key: string]: string } = {
  CREATED: 'app.created',
  PROCESSING: 'app.processingB2b',
  PICKED: 'app.picked',
  BARCODE_REQURIED: 'app.barcodeRequired',
  SHIPMENT_READY: 'app.shipmetnReady',
  COMPLETE: 'app.completed',
  CANCELED: 'app.canceled',
};

export const B2B_SHIPMENT_METHOD_BY_METHOD: { [key: string]: string } = {
  PicknPack: 'app.pnpDelivery',
  Custom: 'app.transportLabel',
};

export enum WarehouseOperations {
  PROCESSING = 'app.scannedOperation',
  ACCEPTED = 'app.receivedOperation',
}

export const RETURNS_SHIPMENT_TABLE_CELLS_ARRAY = [
  'app.returnNo',
  'app.shipmentNo',
  'app.warehouse',
  'app.cost',
  'app.trackingNumber',
  'app.warehouseStatus',
  'app.acceptedProducts',
  'app.action',
];

export enum sideBarListItems {
  RETURNS = 'returns',
  SENDING = 'sending',
  SENDING_TO_WAREHOUSE = 'sendingToWarehouse',
  PRODUCTS_AND_WAREHOUSE = 'productsAndWarehouse',
  ORDERS = 'orders',
  INTEGRATION = 'integration',
  REPORTS = 'reports',
}

export enum TableHeadCellsEnums {
  NUMBER = 'number',
  ID = 'id',
  PRODUCT_SKU = 'productSku',
  PRODUCT_NAME = 'productName',
  QUANTITY = 'quantity',
  PRICE = 'price',
  PHOTO = 'photo',
}

export const SHIPMENT_FROM_WAREHOUSE_TABLE_CELLS_ARRAY_WITH_LABEL = [
  'app.number',
  'app.recipient',
  'app.product',
  'app.cost',
  'app.orderNumberLabel',
  'app.trackingNumber',
  'app.status',
  'app.payment',
  'app.action',
];

export const SHIPMENT_FROM_WAREHOUSE_TABLE_CELLS_ARRAY_WITHOUT_LABEL = [
  'app.number',
  'app.product',
  'app.cost',
  'app.orderNumberLabel',
  'app.trackingNumber',
  'app.status',
  'app.payment',
  'app.action',
];

export const B2B_SHIPMENT_TABLE_CELLS_ARRAY = [
  'app.number',
  'app.warehouse',
  'app.shippingPoint',
  'app.quantityOfGoods',
  'app.cost',
  'app.shippingId',
  'app.shippingDate',
  'app.status',
  'app.payment',
  'app.action',
];

export const UNDECLARED_ARRIVAL_TABLE_CELLS_ARRAY = [
  'app.ID',
  'app.date',
  'app.destination',
  'app.trackingNumber',
  'app.warehouseStatus',
  'app.acceptedProducts',
  'app.action',
];

export const HISTORY_OF_MOVEMENT_TABLE_CELLS_ARRAY = [
  'app.dateTime',
  'app.operationType',
  'app.details',
  'app.quantity',
];

export const PRODUCTS_LIST_TABLE_CELLS_ARRAY = [
  'app.photo',
  'app.ID',
  'app.created',
  'app.productName',
  'app.sku',
  'app.inTransit',
  'app.available',
  'app.reserved',
  'app.price',
  'app.action',
];

export const NOTIFICATIONS_TABLE_CELLS_ARRAY = [
  'app.date',
  'app.themeNotifications',
  'app.textNotifications',
];

export const B2B_SHIPMENTS_PRODUCTS_TABLE_CELLS_ARRAY = [
  'app.product',
  'app.quantity',
  '',
];

export enum colorsEnum {
  RED = 'red',
  GREEN = 'green',
  BLUE = 'blue',
}

export enum deliveryOptionsEnum {
  AMAZON_LOGISTICS = 'amazon logistics',
  UPS = 'ups',
  DHL = 'dhl',
  USPS = 'usps',
  FEDEX = 'fedex',
  TNT = 'tnt',
  POST_RUSSIA = 'post_russia',
  DPD = 'dpd',
  APC = 'apc',
  BOXBERRY = 'boxberry',
  OTHER = 'other',
}

export enum deliveryExplainEnums {
  CANCELED = 'Отменен',
  CREATED = 'Создан',
}

export enum doghuntStatusesEnums {
  CANCELED = 'cancel',
  DELIVERED = 'delivered',
  EXCEPTION = 'exception',
  PENDING = 'pending',
  TRANSIT = 'transit',
}

export const GIFT_MESSAGE_LINK = 'https://picknpack.pro/manual/gift-message/';
export const GIFT_WRAP_LINK = 'https://picknpack.pro/manual/gift-wrapping/';
