import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  input: {
    display: 'none',
  },
  image: {
    width: '338px',
    height: '338px',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  imageComponent: {
    objectFit: 'cover',
    objectPosition: 'center',
  },
  icon: { width: '38px', height: '38px', marginRight: '24px' },
  uploadWrapper: { cursor: 'pointer' },
}));
