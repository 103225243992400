import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  filtersItemSelect: {
    width: 'auto',
    '& .MuiInput-formControl': {
      border: 'none',

      [theme.breakpoints.down('xs')]: {
        '& .MuiInput-input': {
          paddingLeft: 0,
        },
      },
    },
  },
  filtersSelectLimitWrapper: {
    fontSize: '14px',
    lineHeight: '20px',
  },
}));
