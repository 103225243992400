import React from 'react';
import { FormControl, FormHelperText, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import {
  B2BShipmentPoint,
  CreateB2BOrderInput,
} from '../../../generated/graphql';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { Field, FieldProps, useFormikContext } from 'formik';

const B2bShipmentSendFormStep4 = ({
  shipmentPoints,
}: {
  shipmentPoints: B2BShipmentPoint[];
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<CreateB2BOrderInput>();

  const handleWarehouseChange = (
    _: React.ChangeEvent<{}>,
    value: B2BShipmentPoint | null,
  ) => {
    setFieldValue('shipmentPointId', value ? Number(value.id) : 0);
  };

  return (
    <div>
      <Field name={'shipmentPointId'}>
        {({ meta }: FieldProps) => (
          <FormControl
            style={{ marginBottom: '24px' }}
            error={!!(meta.touched && meta.error)}
          >
            <Autocomplete
              className={classes.selectContacts}
              id='warehouse-autocomplete'
              options={shipmentPoints}
              getOptionLabel={(option) => option?.name || ''}
              getOptionSelected={(option, value) =>
                Number(option.id) === Number(value)
              }
              value={
                shipmentPoints.find(
                  (item) => Number(item.id) === values.shipmentPointId,
                ) || null
              }
              onChange={handleWarehouseChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('app.chooseShipmetPoint')}
                  variant='standard'
                />
              )}
            />

            {meta.touched && meta.error && (
              <FormHelperText>{t(meta.error)}</FormHelperText>
            )}
          </FormControl>
        )}
      </Field>
    </div>
  );
};

export default B2bShipmentSendFormStep4;
