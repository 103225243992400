import React from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';

import { Warehouse } from '../../../generated/graphql';
import { TSetStateString } from '../../../interfaces';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { Field, FieldProps, useFormikContext } from 'formik';
import { COLORS } from '../../../utils/constants';

const B2bShipmentSendFormStep1: React.FC<{
  warehouseList: Warehouse[] | null;
  setWarehouseId: TSetStateString;
}> = ({ warehouseList, setWarehouseId }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<any>();

  const handleWarehouseChange = (
    e: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>,
  ) => {
    setFieldValue('warehouseId', e.target.value);
    setWarehouseId(String(e.target.value));
  };

  return (
    <div>
      <Typography className={classes.contactFieldsTitle} variant='h3'>
        {t('app.from')}
      </Typography>
      <Field name={'warehouseId'}>
        {({ field: { value, ...field }, meta }: FieldProps) => (
          <FormControl style={{ marginBottom: '24px' }} variant='standard'>
            <InputLabel shrink={false} htmlFor='warehouse'>
              {t('app.selectWarehouse')}
            </InputLabel>
            <Select
              className={classes.selectContacts}
              id='warehouse'
              value={values.warehouseId}
              onChange={handleWarehouseChange}
            >
              <MenuItem disabled style={{ display: 'none' }}>
                {t('app.chooseWarehouse')}
              </MenuItem>
              {warehouseList?.map((item: Warehouse) => (
                <MenuItem key={item.code} value={Number(item.id)}>
                  {item.code}
                </MenuItem>
              ))}
            </Select>
            {meta?.touched && !!meta?.error && (
              <FormHelperText style={{ color: COLORS.RED }}>
                {meta?.error}
              </FormHelperText>
            )}
          </FormControl>
        )}
      </Field>
    </div>
  );
};

export default B2bShipmentSendFormStep1;
