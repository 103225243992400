export const headCells = [
  { label: 'app.number', name: 'number' },
  { label: 'app.photo', name: 'photo' },
  { label: 'app.id', name: 'id' },
  { label: 'app.sku', name: 'productSku' },
  { label: 'app.product', name: 'productName' },
  { label: 'app.quantity2', name: 'quantity' },
  { label: 'app.weightKg', name: 'weight' },
  { label: 'app.price', name: 'price' },
];
