import React from 'react';
import { Box, Divider, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useGetWhiteLabelConfigQuery } from '../../../generated/graphql';

const LegalInformation: React.FC = () => {
  const { t } = useTranslation();
  const { data: configData } = useGetWhiteLabelConfigQuery();

  const userAgreementLink =
    configData?.getWhiteLabelConfig?.userAgreementLink || '';

  const companyDetails = configData?.getWhiteLabelConfig?.companyDetails || '';
  return (
    <div>
      <Box m={'15px 0'}>
        <Typography variant='h2' align='center'>
          {t('app.legalInformation')}
        </Typography>
      </Box>
      <Divider />

      <Box m={'15px 0'}>
        <a href={companyDetails}>{t('app.companyDetails')}</a>
        <br />
        <a href={userAgreementLink}>{t('app.userAgreement')}</a>
      </Box>
    </div>
  );
};

export default LegalInformation;
