import React, { ChangeEvent, useState } from 'react';
import { TRoutes } from '../../../utils/helpers';
import SelectNextLink from '../../../components/SelectNextLink/SelectNextLink';
import ShipmentFromWarehouseIcon from '../../../assets/img/shipment/shipment_from_warehouse.png';
import B2bSendIcon from '../../../assets/img/shipment/shipment_b2b_create.png';
import UploadFile from '../../../assets/img/icon_upload_file.png';
import UploadFileBlack from '../../../assets/img/icon_upload_file_black.png';
import ModalBox from '../../../components/ModalBox/ModalBox';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import useStyles from './styles';
import {
  Warehouse_Shipment_Order_Type,
  useGetWhiteLabelConfigQuery,
  useImportOrdersWihoutLableFromExelMutation,
  useImportOrdersWihtLableFromExelMutation,
} from '../../../generated/graphql';
import { useSnackbar } from 'notistack';
import { QUERY_WAREHOUSE_SHIPMENT_ORDERS } from '../../../GraphQL/queries/getWarehouseShipmentOrders';

const options = [
  { id: 1, label: 'app.withBuyLabel' },
  { id: 2, label: 'app.withoutBuyLabel' },
];

export default () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [isOpen, setIsOpen] = useState(false);
  const [b2cModalIsOpen, setB2cModalIsOpen] = useState(false);
  const [type, setType] = useState(1);
  const [file, setFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState('');

  const { data: configData } = useGetWhiteLabelConfigQuery();

  const [
    importOrdersWihoutLableFromExelMutation,
    { loading: importOrdersWihoutLableLoading },
  ] = useImportOrdersWihoutLableFromExelMutation({
    onError: (error) => {
      if (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    },
  });
  const [
    importOrdersWihLableFromExelMutation,
    { loading: importOrdersWihLableLoading },
  ] = useImportOrdersWihtLableFromExelMutation({
    onError: (error) => {
      if (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    },
  });

  const loading = importOrdersWihoutLableLoading || importOrdersWihLableLoading;

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  // b2c
  const onB2COpen = () => {
    setB2cModalIsOpen(true);
  };

  const onB2CClose = () => {
    setB2cModalIsOpen(false);
  };

  const onChangeHandler = (
    e: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>,
  ) => {
    setType(Number(e.target.value));
  };

  const handleUploadFile = (e: ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = e.target.files && e.target.files[0];

    if (uploadedFile) {
      setFile(uploadedFile);
      setFileName(uploadedFile.name);
    }
    e.target.value = '';
  };

  const handleImportOrders = () => {
    if (type === 1) {
      importOrdersWihLableFromExelMutation({
        variables: { file },
        onCompleted: (response) => {
          if (response.importOrdersWihtLableFromExel?.isSuccess) {
            enqueueSnackbar(t('app.succesfullyUploaded'), {
              variant: 'success',
            });
            onClose();
            setFile(null);
            setFileName('');
          } else {
            response.importOrdersWihtLableFromExel?.errors?.forEach((error) => {
              enqueueSnackbar(`${error?.orderId} ${error?.message}`, {
                variant: 'error',
              });
            });
          }
        },
        refetchQueries: [
          {
            query: QUERY_WAREHOUSE_SHIPMENT_ORDERS,
            variables: { type: Warehouse_Shipment_Order_Type.WithBuyLabel },
          },
        ],
      });
    }
    if (type === 2) {
      importOrdersWihoutLableFromExelMutation({
        variables: { file },
        onCompleted: (response) => {
          if (response.importOrdersWihoutLableFromExel?.isSuccess) {
            enqueueSnackbar(t('app.succesfullyUploaded'), {
              variant: 'success',
            });
            onClose();
            setFile(null);
            setFileName('');
          } else {
            response.importOrdersWihoutLableFromExel?.errors?.forEach(
              (error) => {
                enqueueSnackbar(`${error?.orderId} ${error?.message}`, {
                  variant: 'error',
                });
              },
            );
          }
        },
        refetchQueries: [
          {
            query: QUERY_WAREHOUSE_SHIPMENT_ORDERS,
            variables: { type: Warehouse_Shipment_Order_Type.WithoutBuyLabel },
          },
        ],
      });
    }
  };

  const links = [
    {
      id: 1,

      icon: ShipmentFromWarehouseIcon,
      text: t('app.b2cSend'),
      disabled: false,
      external: false,
      onClick: onB2COpen,
    },

    {
      id: 3,
      route: TRoutes.B2B_ORDER_SEND,
      icon: B2bSendIcon,
      text: t('app.b2bSend'),
      disabled: false,
      external: false,
    },
    {
      id: 4,
      // route: TRoutes.B2B_ORDER_SEND,
      icon: UploadFileBlack,
      text: t('app.importOrders'),
      disabled: false,
      external: false,
      onClick: onOpen,
    },
  ];

  const b2cLinks = [
    {
      id: 1,
      route: TRoutes.SHIPMENT_FROM_WAREHOUSE_CREATE_WITH_LABEL,
      icon: ShipmentFromWarehouseIcon,
      text: t('app.createWarehouseShipmentWithLabel'),
      disabled: false,
      external: false,
    },
    {
      id: 2,
      route: TRoutes.SHIPMENT_FROM_WAREHOUSE_CREATE_WITHOUT_LABEL,
      icon: ShipmentFromWarehouseIcon,
      text: t('app.createWarehouseShipmentWithoutLabel'),
      disabled: false,
      external: false,
    },
  ];

  const userHandbookLink =
    configData?.getWhiteLabelConfig?.userHandbookLink || '';

  return (
    <>
      <SelectNextLink title={t('app.createNewShipment')} links={links} />
      <ModalBox isOpen={isOpen} setOpen={onClose}>
        <Box style={{ width: '296px' }}>
          <Typography
            variant='h6'
            style={{ marginBottom: '24px' }}
            align='center'
          >{`${t('app.importOrders')}`}</Typography>
          <Box
            style={{ marginBottom: '24px' }}
            display={'flex'}
            flexDirection={'column'}
          >
            <InputLabel shrink={false} htmlFor='input-identifiers-type'>
              {t('app.deliveryMethod')}
            </InputLabel>
            <Box display={'flex'}>
              <Select
                onChange={onChangeHandler}
                value={type}
                disableUnderline
                displayEmpty
              >
                {options.map(({ id, label }) => (
                  <MenuItem key={id} value={id}>
                    {t(label as string)}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
          <Typography
            variant='h3'
            style={{ marginBottom: '40px' }}
            className={classes.modalFormTileEtsy}
          >
            {t('app.manyOrdersUpload')}{' '}
            <a
              href={userHandbookLink}
              target='_blank'
              rel='noopener noreferrer'
            >
              {t('app.template')}
            </a>
          </Typography>
          <Box>
            <InputLabel
              htmlFor='upload-product-file'
              className={classes.uploadFileWarapper}
            >
              <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <img
                  className={classes.icon}
                  src={UploadFile}
                  alt={'upload_file'}
                />
                <Typography variant='h3' className={classes.modalFormTileEtsy}>
                  {!!fileName ? fileName : t('app.uploadFile')}
                </Typography>
              </Box>
            </InputLabel>

            <input
              id='upload-product-file'
              style={{ display: 'none' }}
              type='file'
              accept='.xlsx,.xls,.xlsm,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel'
              onChange={handleUploadFile}
            />
          </Box>

          <div className={classes.boxModalButtons}>
            <Button variant='contained' onClick={onClose} color='secondary'>
              {t('app.cancel')}
            </Button>
            <Button
              variant='contained'
              onClick={handleImportOrders}
              disabled={loading || !file}
            >
              {t('app.create')}
            </Button>
          </div>
        </Box>
      </ModalBox>

      {/* B2C  */}

      <ModalBox isOpen={b2cModalIsOpen} setOpen={onB2CClose}>
        <Box style={{ width: '460px', maxWidth: '100%' }}>
          <SelectNextLink title={t('app.createNewShipment')} links={b2cLinks} />
        </Box>
      </ModalBox>
    </>
  );
};
