import React from 'react';
import { Link } from 'react-router-dom';
import makeStyles from './styles';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface ILink {
  id: number;
  route?: string;
  icon: string | React.ElementType;
  text: string;
  external: boolean;
  disabled: boolean;
  onClick?: () => void;
}

const SelectNextLink: React.FC<{
  title: string;
  links: ILink[];
}> = ({ title, links }) => {
  const classes = makeStyles({ linksLeght: links?.length });

  return (
    <>
      <Typography
        variant='h2'
        align='center'
        gutterBottom
        className={classes.title}
      >
        {title}
      </Typography>

      <ul className={classes.list}>
        {links.map((link) => {
          return (
            <SelectNextLinkListItem
              link={link}
              key={link.id}
              linksLeght={links?.length}
            />
          );
        })}
      </ul>
    </>
  );
};

interface ISelectNextLinkListItemProps {
  link: ILink;
  linksLeght: number;
}

const SelectNextLinkListItem = ({
  link,
  linksLeght,
}: ISelectNextLinkListItemProps) => {
  const classes = makeStyles({ linksLeght });
  const { t } = useTranslation();

  const icon =
    link.text === t('app.warehouse').toUpperCase() ? (
      <img
        className={classes.iconWarehouse}
        //@ts-ignore
        src={link.icon}
        alt={link.text}
      />
    ) : typeof link.icon === 'string' ? (
      <img className={classes.icon} src={link.icon} alt={link.text} />
    ) : (
      // it's a MaterialUI icon
      <link.icon className={classes.materialIcon} />
    );

  const text = <span className={classes.text}>{link.text}</span>;
  const linksClasses = `${classes.link} ${
    link.disabled ? classes.disabled : ''
  }`;
  const menuItem =
    !link.route && link.onClick ? (
      <Link className={linksClasses} to={'#'} onClick={link.onClick}>
        {icon} {text}
      </Link>
    ) : link.external ? (
      <a
        className={linksClasses}
        href={link.route}
        target='_blank'
        rel='noopener noreferrer'
      >
        {icon} {text}
      </a>
    ) : (
      <Link className={linksClasses} to={link.route}>
        {icon} {text}
      </Link>
    );

  return <li className={classes.item}>{menuItem}</li>;
};

export default SelectNextLink;
