import React from 'react';
import useStyles from './styles';
import { Link } from 'react-router-dom';
import { TRoutes } from '../../utils/helpers';
import { Container, Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useGetWhiteLabelConfigQuery } from '../../generated/graphql';

const Footer: React.FC = () => {
  const { data } = useGetWhiteLabelConfigQuery();

  const classes = useStyles();

  const { t } = useTranslation();

  const footerSign = data?.getWhiteLabelConfig?.footerSign || '';

  return (
    <footer>
      <Container>
        <div className={classes.footer}>
          <span>{footerSign}</span>

          <Divider className={classes.divider} orientation='vertical' />

          <Link to={TRoutes.LEGAL_INFORMATION}>
            {t('app.legalInformation')}
          </Link>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
