import {
  AppBar,
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { Link, useRouteMatch } from 'react-router-dom';
import { logout, TRoutes } from '../../utils/helpers';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import PersonIcon from '@material-ui/icons/Person';
import VpnKeyRoundedIcon from '@material-ui/icons/VpnKeyRounded';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import StoreIcon from '@material-ui/icons/Store';
import LanguageIcon from '@material-ui/icons/Language';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { createStylesHeader } from './styles';
import {
  GetCurrentUserQuery,
  User,
  useChangePasswordMutation,
  useGetMyLatesUnreadNotificationsListLazyQuery,
  useGetWhiteLabelConfigQuery,
  useSubscriptionSubscription,
} from '../../generated/graphql';
import { useHistory, useLocation } from 'react-router';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Else, If, Then } from 'react-if';

import HowToRegIcon from '@material-ui/icons/HowToReg';
import SettingsIcon from '@material-ui/icons/Settings';
import ContactsIcon from '@material-ui/icons/Contacts';
import HelpIcon from '@material-ui/icons/Help';
import { COLORS } from '../../utils/constants';
import ModalBox from '../ModalBox/ModalBox';
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { changePasswordSchema } from '../../utils/validationSchemes';
import { TChangePasswordForm } from '../../interfaces';
import { useSnackbar } from 'notistack';
import PackageIcon from '../../assets/img/icon_package_black.png';
import { useChangeLanguage } from '../../hooks/useChangeLanguage';
import { useTranslation } from 'react-i18next';
import NotificationsIconComponent from '../NotificationIconComponent/NotificationIconComponent';
import NewNotificationsListComponent from '../NotificationsComponents/NewNotificationsListComponent/NewNotificationsListComponent';

export const Header: React.FC<{
  isSidebarDisabled: boolean;
  isSidebarHidden: boolean;
  isMobileOpen: boolean;
  setMobileOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currentUser: GetCurrentUserQuery | null;
  user?: User;
}> = ({
  isSidebarDisabled,
  isSidebarHidden,
  isMobileOpen,
  setMobileOpen,
  currentUser,
  user,
}) => {
  const history = useHistory();
  const theme = useTheme();
  const location = useLocation();
  const currentPath = location.pathname;
  const hidden = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = createStylesHeader(isSidebarHidden, isSidebarDisabled);
  const { enqueueSnackbar } = useSnackbar();

  const isRouteMatchAuthSignIn = useRouteMatch(TRoutes.AUTH_SIGN_IN);
  const isRouteMatchAuthSignUp = useRouteMatch(TRoutes.AUTH_SIGN_UP);

  const { t } = useTranslation();

  const [isShowPassword, toggleShowPassword] = useState(false);
  const [isOpenChangePsswordModal, setIsOpenChangePasswordModal] =
    useState(false);

  const [isShowNotificationsList, setIsShowNotificationsList] = useState(false);

  const [changePasswordMutation] = useChangePasswordMutation();

  const { changeLang, currentLanguage } = useChangeLanguage();

  const { data: configData } = useGetWhiteLabelConfigQuery();

  const [getMyLatestMessagesLazy, { data }] =
    useGetMyLatesUnreadNotificationsListLazyQuery({
      onError: (error) => {
        if (error) {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      },
      fetchPolicy: 'network-only',
    });

  const handleRefetchMyLatestMessages = () => {
    if (!!user) {
      getMyLatestMessagesLazy({
        variables: {
          offset: 0,
          limit: 1,
        },
      });
    }
  };

  useEffect(() => {
    handleRefetchMyLatestMessages();
    // eslint-disable-next-line
  }, []);

  const { data: subscriptionData } = useSubscriptionSubscription({
    onComplete: () => {
      handleRefetchMyLatestMessages();
      enqueueSnackbar(t('app.newNotification'), { variant: 'success' });
    },
    onError: (error) => {
      handleRefetchMyLatestMessages();
      if (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    },
  });

  useEffect(() => {
    if (subscriptionData) {
      enqueueSnackbar(t('app.newNotification'), { variant: 'success' });
      handleRefetchMyLatestMessages();
    }
    // eslint-disable-next-line
  }, [subscriptionData]);

  const handleSubmit = (
    values: TChangePasswordForm,
    { setSubmitting }: FormikHelpers<TChangePasswordForm>,
  ) => {
    changePasswordMutation({
      variables: {
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      },
    })
      .then(({ data }) => {
        enqueueSnackbar(t('app.newPasswordSaved'), {
          variant: 'success',
        });
        setIsOpenChangePasswordModal(false);
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      })
      .finally(() => setSubmitting(false));
  };

  const getHeaderTitleText = () => {
    switch (currentPath) {
      case TRoutes.MAIN:
        return t('app.dashboard');

      default:
        return '';
    }
  };

  const userHandbookLink =
    configData?.getWhiteLabelConfig?.userHandbookLink || '';

  return (
    <>
      <AppBar
        position={isSidebarDisabled ? 'static' : 'absolute'}
        className={classes.appBar}
      >
        <Toolbar className={classes.toolbar}>
          <If condition={!isSidebarDisabled}>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
              <IconButton
                aria-label='open drawer'
                edge='start'
                className={classes.toggleMenu}
                onClick={() => setMobileOpen(!isMobileOpen)}
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </If>
          <If condition={!!user}>
            <Then>
              <Box className={classes.menuWrapper}>
                <Typography
                  variant='h2'
                  align='center'
                  className={classes.headerTitle}
                >
                  {getHeaderTitleText()}
                </Typography>
                <Box className={classes.linkWrapper}>
                  <Tooltip title={t('app.goToTransactionHistory')}>
                    <Link
                      to={TRoutes.ACCOUNT}
                      className={classes.balance}
                      style={{ marginLeft: 'auto' }}
                    >
                      <AccountBalanceWalletIcon
                        className={classes.balanceIcon}
                      />
                      {hidden ? null : `${t('app.balance')}:`}
                      {currentUser?.currentUser?.balance}
                      <span>₽</span>
                    </Link>
                  </Tooltip>

                  <Tooltip title={t('app.help')}>
                    <a
                      className={classes.headerIconWrap}
                      href={userHandbookLink}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <HelpIcon style={{ color: COLORS.BLACK }} />
                    </a>
                  </Tooltip>
                  <Box></Box>
                  <NotificationsIconComponent
                    onClick={() => {
                      setIsShowNotificationsList(true);
                    }}
                    count={Number(
                      data?.getMyLatesUnreadNotificationsList?.count,
                    )}
                  />
                  {isShowNotificationsList ? (
                    <NewNotificationsListComponent
                      onCloseList={() => {
                        setIsShowNotificationsList(false);
                      }}
                      handleRefetchMyLatestMessages={
                        handleRefetchMyLatestMessages
                      }
                      messageCount={Number(
                        data?.getMyLatesUnreadNotificationsList?.count,
                      )}
                    />
                  ) : null}

                  <Select className={classes.userSettingsSelect} value={0}>
                    <MenuItem value={0} style={{ display: 'none' }}>
                      <Tooltip title={t('app.settings')}>
                        <IconButton className={classes.headerIconWrap}>
                          <SettingsIcon style={{ color: COLORS.BLACK }} />
                        </IconButton>
                      </Tooltip>
                    </MenuItem>
                    <MenuItem>
                      <Tooltip title={t('app.goToAddressBook')}>
                        <Link to={TRoutes.CONTACTS} className={classes.person}>
                          <ContactsIcon className={classes.personIcon} />
                          {t('app.addressBook')}
                        </Link>
                      </Tooltip>
                    </MenuItem>
                    <MenuItem>
                      <Tooltip title={t('app.goToMyPackaging')}>
                        <Link
                          to={TRoutes.SETTINGS_MY_PACKAGES}
                          className={classes.person}
                        >
                          <img
                            src={PackageIcon}
                            alt='PackageIcon'
                            className={classes.personImg}
                          />
                          {t('app.myPackaging')}
                        </Link>
                      </Tooltip>
                    </MenuItem>
                    <MenuItem>
                      <Tooltip title={t('app.goToStores')}>
                        <Link to={TRoutes.MY_SHOPS} className={classes.person}>
                          <StoreIcon className={classes.personIcon} />
                          {t('app.stores')}
                        </Link>
                      </Tooltip>
                    </MenuItem>
                  </Select>

                  <Select
                    className={classes.languageSelect}
                    value={0}
                    onChange={changeLang}
                  >
                    <MenuItem value={0} style={{ display: 'none' }}>
                      <IconButton className={classes.headerIconWrap}>
                        <LanguageIcon style={{ color: COLORS.BLACK }} />
                      </IconButton>
                      <p className={classes.language}>
                        {currentLanguage.charAt(0).toUpperCase() +
                          currentLanguage.slice(1)}
                      </p>
                    </MenuItem>
                    <MenuItem value={'ru'}>
                      <div className={classes.language}>
                        {t('app.languageRussian')}
                      </div>
                    </MenuItem>
                    <MenuItem value={'en'}>
                      <div className={classes.language}>
                        {t('app.languageEnglish')}
                      </div>
                    </MenuItem>
                  </Select>

                  <Select className={classes.userInfoSelect} value={0}>
                    <MenuItem value={0} style={{ display: 'none' }}>
                      <Link
                        to={TRoutes.PERSONAl_VERIFY}
                        className={classes.person}
                      >
                        <PersonIcon className={classes.personIcon} />
                        {!!user?.name && hidden ? null : user?.name}
                      </Link>
                    </MenuItem>
                    <MenuItem>
                      <Tooltip title={t('app.goToPersonalData')}>
                        <Link
                          to={TRoutes.PERSONAl_VERIFY}
                          className={classes.person}
                        >
                          <PersonOutlinedIcon className={classes.personIcon} />
                          {t('app.profile')}
                        </Link>
                      </Tooltip>
                    </MenuItem>
                    <MenuItem>
                      <Tooltip title={t('app.goToPersonalData')}>
                        <Link
                          to={TRoutes.PERSONAl_DATA_VERIFY}
                          className={classes.person}
                        >
                          <HowToRegIcon className={classes.personIcon} />
                          {t('app.verification')}
                        </Link>
                      </Tooltip>
                    </MenuItem>
                    <MenuItem>
                      <Tooltip title={t('app.changePassword')}>
                        <Link
                          to={'#'}
                          onClick={() => {
                            setIsOpenChangePasswordModal(true);
                          }}
                          className={classes.person}
                        >
                          <VpnKeyRoundedIcon className={classes.personIcon} />{' '}
                          {t('app.changePassword')}
                        </Link>
                      </Tooltip>
                    </MenuItem>
                    <MenuItem>
                      <Tooltip title={t('app.signOutClearLocalStorage')}>
                        <Link
                          to={'#'}
                          className={classes.person}
                          onClick={() => {
                            logout(history);
                          }}
                        >
                          <ExitToAppIcon className={classes.personIcon} />{' '}
                          {t('app.exit')}
                        </Link>
                      </Tooltip>
                    </MenuItem>
                  </Select>
                </Box>
              </Box>
            </Then>

            <Else>
              <Select
                className={classes.languageSelect}
                value={0}
                onChange={changeLang}
              >
                <MenuItem value={0} style={{ display: 'none' }}>
                  <IconButton className={classes.headerIconWrap}>
                    <LanguageIcon style={{ color: COLORS.BLACK }} />
                  </IconButton>
                  <p className={classes.language}>
                    {currentLanguage.charAt(0).toUpperCase() +
                      currentLanguage.slice(1)}
                  </p>
                </MenuItem>
                <MenuItem value={'ru'}>
                  <div className={classes.language}>
                    {t('app.languageRussian')}
                  </div>
                </MenuItem>
                <MenuItem value={'en'}>
                  <div className={classes.language}>
                    {t('app.languageEnglish')}
                  </div>
                </MenuItem>
              </Select>

              {!isRouteMatchAuthSignIn && !isRouteMatchAuthSignUp && (
                <>
                  <Divider orientation='vertical' />
                  <Tooltip
                    className={classes.headerLink}
                    title={t('app.logInToAccount')}
                  >
                    <Link to={TRoutes.AUTH_SIGN_IN}>
                      <AccountCircleIcon /> {t('app.logIn')}
                    </Link>
                  </Tooltip>
                </>
              )}
            </Else>
          </If>
        </Toolbar>
      </AppBar>
      <ModalBox
        isOpen={isOpenChangePsswordModal}
        setOpen={setIsOpenChangePasswordModal}
      >
        <Formik
          initialValues={{
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
          }}
          validationSchema={changePasswordSchema}
          onSubmit={handleSubmit}
        >
          {(props: FormikProps<TChangePasswordForm>) => {
            const { isSubmitting, getFieldProps, getFieldMeta } = props;
            return (
              <Form noValidate autoComplete='off'>
                <Field name='oldPassword'>
                  {() => (
                    <FormControl
                      error={
                        !!(
                          getFieldMeta('password').touched &&
                          getFieldMeta('password').error
                        )
                      }
                      className={classes.formControl}
                    >
                      <InputLabel shrink={false} htmlFor='input-password'>
                        {t('app.oldPassword')}
                      </InputLabel>
                      <Input
                        disableUnderline
                        fullWidth
                        id='input-password'
                        type={isShowPassword ? 'text' : 'password'}
                        {...getFieldProps('oldPassword')}
                        endAdornment={
                          <InputAdornment
                            position='end'
                            onClick={() => toggleShowPassword(!isShowPassword)}
                          >
                            <IconButton aria-label='toggle password visibility'>
                              {isShowPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {getFieldMeta('oldPassword').touched &&
                        getFieldMeta('oldPassword').error && (
                          <FormHelperText>
                            {getFieldMeta('oldPassword').error}
                          </FormHelperText>
                        )}
                    </FormControl>
                  )}
                </Field>
                <Field name='newPassword'>
                  {() => (
                    <FormControl
                      error={
                        !!(
                          getFieldMeta('newPassword').touched &&
                          getFieldMeta('newPassword').error
                        )
                      }
                      className={classes.formControl}
                    >
                      <InputLabel shrink={false} htmlFor='input-password'>
                        {t('app.newPassword')}
                      </InputLabel>
                      <Input
                        disableUnderline
                        fullWidth
                        id='input-password'
                        type={isShowPassword ? 'text' : 'password'}
                        {...getFieldProps('newPassword')}
                        endAdornment={
                          <InputAdornment
                            position='end'
                            onClick={() => toggleShowPassword(!isShowPassword)}
                          >
                            <IconButton aria-label='toggle password visibility'>
                              {isShowPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {getFieldMeta('newPassword').touched &&
                        getFieldMeta('newPassword').error && (
                          <FormHelperText>
                            {getFieldMeta('newPassword').error}
                          </FormHelperText>
                        )}
                    </FormControl>
                  )}
                </Field>
                <Field name='confirmPassword'>
                  {() => (
                    <FormControl
                      error={
                        !!(
                          getFieldMeta('confirmPassword').touched &&
                          getFieldMeta('confirmPassword').error
                        )
                      }
                      className={classes.formControl}
                    >
                      <InputLabel
                        shrink={false}
                        htmlFor='input-confirm-password'
                      >
                        {t('app.confirmNewPassword')}
                      </InputLabel>
                      <Input
                        disableUnderline
                        fullWidth
                        id='input-confirm-password'
                        type={isShowPassword ? 'text' : 'password'}
                        {...getFieldProps('confirmPassword')}
                        endAdornment={
                          <InputAdornment
                            position='end'
                            onClick={() => toggleShowPassword(!isShowPassword)}
                          >
                            <IconButton aria-label='toggle password visibility'>
                              {isShowPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {getFieldMeta('confirmPassword').touched &&
                        getFieldMeta('confirmPassword').error && (
                          <FormHelperText>
                            {getFieldMeta('confirmPassword').error}
                          </FormHelperText>
                        )}
                    </FormControl>
                  )}
                </Field>
                <div className={classes.boxModalButtons}>
                  <Button
                    variant='contained'
                    onClick={() => {
                      setIsOpenChangePasswordModal(false);
                    }}
                    color='secondary'
                  >
                    {t('app.close')}
                  </Button>

                  <Button
                    type='submit'
                    variant='contained'
                    disabled={isSubmitting}
                  >
                    {t('app.change')}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </ModalBox>
    </>
  );
};
