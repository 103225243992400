import { Product } from '../../../generated/graphql';
import { TRoutes } from '../../../utils/helpers';
import SearchIcon from '@material-ui/icons/Search';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CancelIcon from '@material-ui/icons/Cancel';
import { Edit as EditIcon } from '@material-ui/icons';
import iconBarcode from '../../../assets/img/icon_barcode.svg';
import { colorsEnum } from '../../../utils/constants';

export const getActionOptions = (
  product: Product,
  openModalEditProduct: () => void,
  openModalForRemoveHandler: () => void,
  openModalCopyProductHandler: () => void,
) => {
  return [
    {
      link: `${
        TRoutes.SETTINGS_MY_PRODUCT_DETAILS_WITHOUT_ID
      }${product?.id?.toString()}`,
      Icon: SearchIcon,
      title: 'app.view',
      color: colorsEnum.GREEN,
      tooltipText: 'app.clickToOpenViewShipments',
    },
    {
      link: `/api/getBarcode/${product?.barcodeId}`,
      imgSrc: iconBarcode,
      title: 'app.barcode',
      target: '_blank',
      tooltipText: 'app.clickToOpenBarcode',
    },

    {
      link: '#',
      Icon: EditIcon,
      title: 'app.edit',
      color: colorsEnum.GREEN,
      onClick: openModalEditProduct,
    },
    {
      link: '#',
      Icon: FileCopyIcon,
      title: 'app.copy',
      color: colorsEnum.BLUE,
      onClick: openModalCopyProductHandler,
    },
    {
      link: `#`,
      Icon: CancelIcon,
      title: 'app.delete',
      onClick: openModalForRemoveHandler,
      disabled:
        !!product.productAvailabilityQuantity?.inTransitQuantity ||
        !!product.productAvailabilityQuantity?.availableQuantity ||
        !!product.productAvailabilityQuantity?.reservedQuantity,
      color: colorsEnum.RED,
      tooltipText: 'app.clickToDeleteOrder',
    },
  ];
};
