import { gql } from '@apollo/client';

export const QUERY_MY_PRODUCT_BY_ID = gql`
  query getMyProductById($productId: Int!) {
    getMyProductById(productId: $productId) {
      id
      name
      sku
      detailsDescriptionRU
      detailsDescriptionEN
      state
      trademark
      declaredValue
      country {
        id
        nameEng
      }
      netWeight
      code
      userId
      fnsku
      upc
      actualDimensions {
        id
        warehouseShipmentId
        weight
        length
        width
        height
      }
      declaredDimensions {
        id
        warehouseShipmentId
        weight
        length
        width
        height
      }
      productAvailabilityQuantity {
        inTransitQuantity
        reservedQuantity
        availableQuantity
      }
      createdAt
      barcodeId
      isCustomBarcodeId
      currency
      photo
    }
  }
`;
