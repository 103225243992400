import React, { useEffect, useState } from 'react';
import { Divider, Grid, Typography } from '@material-ui/core';
import { Else, If, Then } from 'react-if';
import useStyles from './styles';
import { useSnackbar } from 'notistack';
import { ShowLoadingText } from '../../utils/helperComponents';
import {
  Transaction,
  useGetPaymentHistoryListByUserIdLazyQuery,
} from '../../generated/graphql';
import PaymentsTable from '../../components/PaymentsTable/PaymentsTable';
import { useDebouncedCallback } from 'use-debounce';
import { LIMIT_ITEM_PAGE } from '../../utils/helpers';
import { useTranslation } from 'react-i18next';

export default function PaymentsHistory() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const [filter, setFilter] = useState({
    page: 1,
  });

  const [
    getPaymentsLazy,
    { loading: isLoadingPayments, error: errorPayments, data: dataPayments },
  ] = useGetPaymentHistoryListByUserIdLazyQuery({
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [filter]);

  const refetch = useDebouncedCallback(() => {
    getPaymentsLazy({
      variables: {
        offset: (filter.page! - 1) * LIMIT_ITEM_PAGE,
        limit: LIMIT_ITEM_PAGE,
      },
    });
  });

  useEffect(() => {
    getPaymentsLazy();
    // eslint-disable-next-line
  }, []);

  if (errorPayments?.message)
    enqueueSnackbar(errorPayments.message, { variant: 'error' });

  return (
    <>
      <Grid container spacing={2} justify='center'>
        <Grid item xs={12} sm={12}>
          <Typography variant='h2' className={classes.mainTitle} align='center'>
            {t('app.balanceAndPayment').toUpperCase()}
          </Typography>
          <Divider />
        </Grid>
      </Grid>

      <If condition={isLoadingPayments}>
        <Then>
          <ShowLoadingText name={t('app.paymentHistory')} />
        </Then>
        <Else>
          <Grid container spacing={4} justify='center'>
            <Typography variant='h6' className={classes.mainTitle}>
              {t('app.transactionHistory')}
            </Typography>
            <PaymentsTable
              transactions={
                (dataPayments?.getPaymentHistoryListByUserId
                  ?.rows as Transaction[]) || []
              }
              count={dataPayments?.getPaymentHistoryListByUserId?.count || 1}
              page={filter.page!}
              setFilter={setFilter}
            />
          </Grid>
        </Else>
      </If>
    </>
  );
}
