import React from 'react';
import { MenuItem, Select, TableCell, TableRow } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { TRoutes } from '../../../utils/helpers';
import { ActionsItem } from '../../../utils/helperComponents';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import { IProductsListTableRowComponent } from './types';
import { getActionOptions } from './options';
import moment from 'moment';
import { DATE_FORMAT } from '../../../utils/constants';
import PhotoComponent from '../../UploadProductPhoto/PhotoComponent';

const ProductsListTableRowComponent = ({
  product,
  setEditingProduct,
  setIsOpenUnitForm,
  setOpenConfirmRemove,
  setProductToRemove,
  setCopyProduct,
}: IProductsListTableRowComponent) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const formattedDate = moment(Number(product?.createdAt)).format(DATE_FORMAT);

  const orderIdTitle = product?.barcodeId?.split('.')[0];

  const openModalForRemoveHandler = () => {
    setOpenConfirmRemove(true);
    setProductToRemove(product);
  };

  const openModalEditProductHandler = () => {
    setEditingProduct(product);
    setIsOpenUnitForm(true);
  };

  const openModalCopyProductHandler = () => {
    setCopyProduct(product);
    setIsOpenUnitForm(true);
  };

  const options = getActionOptions(
    product,
    openModalEditProductHandler,
    openModalForRemoveHandler,
    openModalCopyProductHandler,
  );

  return (
    <TableRow>
      <TableCell align='center'>
        <PhotoComponent photoName={product.photo} width={'46px'} />
      </TableCell>
      <TableCell align='center'>
        <Link
          to={`${
            TRoutes.SETTINGS_MY_PRODUCT_DETAILS_WITHOUT_ID
          }${product?.id?.toString()}`}
        >
          {orderIdTitle}
        </Link>
      </TableCell>
      <TableCell align='center'>{formattedDate}</TableCell>
      <TableCell align='center'>{product.name}</TableCell>
      <TableCell align='center'>{product.sku}</TableCell>
      <TableCell align='center'>
        {product.productAvailabilityQuantity?.inTransitQuantity}
      </TableCell>
      <TableCell align='center'>
        {product.productAvailabilityQuantity?.availableQuantity}
      </TableCell>
      <TableCell align='center'>
        {product.productAvailabilityQuantity?.reservedQuantity}
      </TableCell>
      <TableCell align='center'>
        {`${product?.declaredValue} ${product?.currency}`}
      </TableCell>
      <TableCell align='center'>
        <Select value={t('app.action')}>
          <MenuItem
            value={t('app.action')}
            disabled
            style={{ display: 'none' }}
          >
            {t('app.action')}
          </MenuItem>
          {options?.map((option) => (
            <ActionsItem
              {...option}
              key={option.title}
              itemLinkName={classes.actionMenuItem}
              linkClassName={classes.actionLink}
              iconClassName={classes.actionIcon}
              redColorIconClassName={classes.actionIconCancel}
              greenColorIconClassName={classes.actionIconWatch}
              blueColorIconClassName={classes.actionIconSend}
            />
          ))}
        </Select>
      </TableCell>
    </TableRow>
  );
};

export default ProductsListTableRowComponent;
